/// <reference path='./../_reference.ts' />
module Apex.Components.Directives {
    "use strict";


    export interface ITextareaScope extends angular.IScope {
        ngDisabled?: boolean;
    }

    export class ApxTextarea implements angular.IDirective {
        restrict: string;

        templateUrl: any;
        scope: any;
        link(scope: ITextareaScope, instanceElement: angular.IAugmentedJQuery, instanceAttributes: angular.IAttributes, controller, transclude: angular.ITranscludeFunction): void { }


        constructor() {
            this.restrict = "AE";
            this.templateUrl = 'apx.textarea.html';
            this.scope = {
                ngDisabled: '=',
                ngModel: '='
            };
            this.link = (scope, element: angular.IAugmentedJQuery, attrs: angular.IAttributes) => {
                if (scope.ngDisabled) {
                    element.addClass('k-state-disabled');
                }
            };
        }

        public static factory() {
            var directive = () => {
                return new ApxTextarea();
            };
            return directive;
        }

    }
} 