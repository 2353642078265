/// <reference path='./../_reference.ts' />
module Apex.Components.Directives {
    "use strict";


    export class ApxColSpan implements angular.IDirective {
        restrict = 'A';
        link(scope, element, attributes) {
            element.addClass('apx-padding-2 col-xs-' + attributes.apxColSpan + ' col-md-' + attributes.apxColSpan);

            var oldVal = attributes.apxColSpan;

            attributes.$observe('apxColSpan',(newVal, oldVal) => {
                var classes = element.attr("class").split(' ');
                $.each(classes,(i, c) => {
                    if (c.indexOf("col-") === 0 && c.indexOf("col-md-offset") !== 0 && c.indexOf("col-xs-offset") !== 0) {
                        element.removeClass(c);
                    }
                });
                element.removeClass('col-xs-* col-md-*');
                element.addClass('col-xs-' + newVal + ' col-md-' + newVal);
                oldVal = newVal;
            });
        }

        public static factory() {
            var directive = () => {
                return new ApxColSpan();
            };
            return directive;
        }
    }

}  