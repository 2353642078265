/// <reference path='./../_reference.ts' />
module Apex.Components.Controllers {

    export interface LeafLetScope extends angular.IScope {
        maps: {
            mapControl?: L.Map;
            showMap?: boolean;
            layers?: any,
            center?: {
                lat?: number,
                lng?: number,
                zoom?: number
            },
            defaults?: {
                zoomAnimation?: boolean,
                markerZoomAnimation?: boolean,
                fadeAnimation?: boolean
            },
            markers?: any,
            titles?: any;
            height?:number;
        };
    }
    export class ApxLeafletController extends Core.Controller {
        public static $inject = [Globals.scope, "$timeout", 'leafletData'];
        constructor(private $scope: LeafLetScope, public $timeout, private leafletData) {
            super(arguments, ApxLeafletController.$inject);
            this.$scope.maps = {
                layers: {
                    overlays: {
                        cluster: {
                            name: "cluster",
                            type: "markercluster",
                            visible: true,
                            layerOptions: {
                                maxClusterRadius: 10
                            }
                        }
                    },
                    baselayers:
                    {

                        osm: {
                            name: 'Open Street Map',
                            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                            type: 'xyz'
                        },
                        cartocdn: {
                            name: 'Cartographic',
                            url: 'http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png',
                            type: 'xyz'
                        },
                        ocm: {
                            name: "Open Cycle Map",
                            url: "http://{s}.tile.opencyclemap.org/cycle/{z}/{x}/{y}.png",
                            type: 'xyz'
                        },
                        bar: {
                            name: "other",
                            url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png?bar",
                            type: 'xyz'
                        }
                    }
                },
                center: {
                    lat: 42.17968819665961,
                    lng: 44.0057373046875,
                    zoom: 7
                },
                defaults: {
                    zoomAnimation: true,
                    markerZoomAnimation: true,
                    fadeAnimation: true
                },
                markers: new Array()
            };

            this.$timeout(() => {
                this.leafletData.getMap().then(mp => {
                    this.$scope.maps.mapControl = mp;
                });
            });

        }

       

        addMarkers = (latlon, type) => {
            console.log(latlon);
            if(type==="one")
                this.$scope.maps.markers = new Array();
            this.$scope.maps.markers.push(
                {
                    layer: "cluster",
                    lat: latlon.lat,
                    lng: latlon.lng
                });
        }
    }
}