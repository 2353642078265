/// <reference path='./../_reference.ts' />

module Apex.Components.Directives {
    "use strict";
    export class ApxLookup implements angular.IDirective {
        restrict = 'E';
        replace = true;
        templateUrl = 'apx.lookup.html';
        scope = {
            options: '=',
            ngModel: '=',
            selectorWidth: '=?',
            ngDisabled: "=",
            model: '=?',
            apxPlaceholder: '=?',
            apxRequired: '=?',
            element: '=?',
            eventOnChange: '=?',
            setWidth: '=?'
        };
        controller = Components.Controllers.Lookup.getName();
        link = (scope, element: any, attrs, controller) => {
            var keydownEvent = (e) => {
                var lastKeyCode = e.which;
                switch (lastKeyCode) {
                    case 40:
                        element.find("select").click();
                        break;
                }
            };
            element.on('keydown', keydownEvent);
            scope.element = element;
            scope.$watch('setWidth', (val) => {
                controller.setPopupAutoWith(val);
            });
        };

        public static factory() {
            var directive = () => {
                return new ApxLookup();
            };
            return directive;
        }
    }
}