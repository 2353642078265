/// <reference path='./../_reference.ts' />
module Apex.Components.Controllers {
    export class ExportGrid extends Core.Controller {

        public static $inject = [
            Globals.scope, Globals.rootScope, Globals.translate, 'componentsService'
        ];
        constructor(private $scope: any, private $rootScope: any, private $translate, private componentsService: Components.Services.Components) {
            super(arguments, ExportGrid.$inject);
            $scope.exportToExcel = () => {
                this.exportExcel();
            }
        }
        exportExcel() {
            var exp = new Core.Kendo.ExportDatasource(this.$scope.apxKGrid, this.$translate);
            if (this.$scope.apxKGrid.grid.dataSource.options.transport) {
                this.componentsService.exportToExcel(this.$scope.apxKGrid.grid.dataSource.options.transport.read.url).then(function (result) {
                    exp.saveExcel(result);
                });
            }
            else {
                exp.saveExcel(this.$scope.apxKGrid.grid.dataSource._data);
            }
        }
    }
}
