/// <reference path='./../_reference.ts' />
module Apex.Components.Controllers {


    export class ApxDate extends Core.Controller {

        public static $inject = [
            Globals.scope, Globals.rootScope
        ];

        constructor(private $scope: any, private $rootScope: any) {
            super(arguments, ApxDate.$inject);

            $scope.openDate = $event => {
                $event.preventDefault();
                $event.stopPropagation();
                $scope.isOpened = !$scope.isOpened;
            };

            $scope.dateOptions = {
                formatYear: 'yy',
                startingDay: 1,
                class: 'datepicker'
            };
        }

    }
}