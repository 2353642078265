/// <reference path='./../_reference.ts' />
module Apex.Components.Directives {
    "use strict";

    export class ApxTextboxMask implements angular.IDirective {
        restrict = 'AE';
        replace = true;
        require = 'ngModel';
        scope = {
            ngModel: '=',
            apxMask: '@',
            ngDisabled: '='
        };
        templateUrl = 'apx.textbox.mask.html';

        link(scope: any, element: angular.IAugmentedJQuery, attrs: angular.IAttributes): void { }

        constructor() {
            this.link = (scope: any, element: angular.IAugmentedJQuery, attrs: angular.IAttributes) => {
                if (scope.ngDisabled) {

                }
            };
        }
        public static factory() {
            var directive = () => {
                return new ApxTextboxMask();
            };
            return directive;
        }
    }
} 