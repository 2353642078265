/// <reference path='./../_reference.ts' />
module Apex.Components.Directives {
    "use strict";

    export class ApxModalForm implements angular.IDirective {

        restrict = "E";

        transclude = true;
        scope = {
            apxModalTitle: '@',
            modalInstance: '=',
            apxResultOk: '&'
        };
        templateUrl = 'apx.modal.form.html';

        link(scope, element, attributes) {
            var modalInstance = scope.modalInstance;
            scope.closeForm = (event) => {

                if (typeof modalInstance !== "undefined" && modalInstance !== null) {
                    modalInstance.close();
                }
            };

            scope.resultOk = (itm) => {
                scope.apxResultOk();
            }

            scope.modalID = 'modal-' + scope.$id;
            if (typeof attributes.apxPrint !== "undefined") {
                scope.withPrintButton = true;
            }
        }

        public static factory() {
            var directive = () => {
                return new ApxModalForm();
            };

            return directive;
        }
    }
} 