/// <reference path='./../_reference.ts' />
module Apex.Components.Directives {
    "use strict";

    export class ApxDateLabel implements angular.IDirective {
        restrict = 'E';

        scope = {
            ngModel: '=',
            ngReadonly: '=?',
            minDate: '=?',
            maxDate: '=?',
            dtpRequired: '=?',
            dateOptions: '=?'
        };
        template = '<div class="row">' +
        '<div class="col-md-6">' +
        '<p class="input-group">' +
        '<input type="text" class="form-control" datepicker-popup="{{format}}"' +
        'ng-model="ngModel" is-open="opened"' +
        'min-date="minDate" max-date="maxDate"' +
        'datepicker-options="dateOptions" date-disabled="disabled(date, mode)"' +
        'ng-required="dtpRequired" close-text="Close" ng-readonly="ngReadonly" ng-click="openPopup()" />' +
        '<span class="input-group-btn">' +
        '<button type="button" class="btn btn-default" ng-click="openPopup($event)">' +
        '<i class="glyphicon glyphicon-calendar"></i></button>' +
        '</span>' +
        '</p>' +
        '</div>' +
        '</div>';
        controller($scope) {

            $scope.dateOptions = $scope.dateOptions || {
                formatYear: 'yy',
                startingDay: 1,
                showWeeks: false
            };

            $scope.openPopup = $event => {
                $event.stopPropagation();
                $scope.opened = true;
            };

            // Disable weekend selection
            $scope.disabled = (date, mode) => (mode === 'day' && (date.getDay() === 0 || date.getDay() === 6));

            $scope.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
            $scope.format = $scope.formats[0];
        }

        link($scope, element, attrs) {

        }


        public static factory() {
            var directive = () => {
                return new ApxDateLabel();
            };


            return directive;
        }
    }
} 