/// <reference path='./../_reference.ts' />
module Apex.Components.Directives {
    "use strict";
    export class ApxLeafLetMapExpand implements angular.IDirective {

        restrict = 'A';
        constructor(private $window) {
        }
        link = (scope, element, attrs) => {
            var outSet = Number(attrs.apxLeafLetMapExpand);
            if (outSet == undefined )
                outSet = 0;
            var parent: any;
            function resizeLeafLetMap() {
                var leafLetMapElement = $(element);
                var currentLeafLetMapHeight = $("#pageBody").innerHeight() - (65 + outSet);
                leafLetMapElement.height(currentLeafLetMapHeight);
            }

            $(this.$window).resize(() => {
                resizeLeafLetMap();
            });
            resizeLeafLetMap();
        }

        public static factory() {
            var directive = ($window) => {
                return new ApxLeafLetMapExpand($window);
            };
            directive['$inject'] = ['$window'];
            return directive;
        }
    }
}