/// <reference path='./../_reference.ts' />
module Apex.Components.Directives {
    "use strict";


    export class ApxTabStrip implements angular.IDirective {
        restrict = 'E';
        templateUrl = 'apx.tabstrip.html';
        replace = true;
        scope = {
            maxHeight: '=maxHeight'
        };

        constructor(private $compile) { }
        compile = () => {
            var compile = this.$compile;
            return {
                pre(scope, element, attrs) {
                    if (attrs.maxHeight) {
                        var resizeAll = function () {
                            expandContentDivs(tabStripElement.children(".k-content"));
                        };

                        var tabStripElement = $("#tabstrip").kendoTabStrip({
                            animation: {
                                open: {
                                    effects: "fade"
                                }
                            }
                        });
                        var tabStrip = tabStripElement.data("kendoTabStrip");

                        var expandContentDivs = function (divs) {
                            divs.height(tabStripElement.innerHeight() - tabStripElement.children(".k-tabstrip-items").outerHeight() - 16);
                        };

                        tabStripElement.parent().attr("id", "tabstrip-parent");

                        resizeAll();
                        compile(element)(scope);
                    }
                }
            }
        };

        public static factory() {
            var directive = ($compile) => {
                return new ApxTabStrip($compile);
            };
            return directive;
        }

    }
} 