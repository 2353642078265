/// <reference path='./../_reference.ts' />
module Apex.Components.Directives {
    "use strict";
    export class ApxToggle implements angular.IDirective {
        restrict = "E";
        replace = true;
        template = '<div><label class="apx-toggle"><input type="checkbox" checked /><span data-on="On" data-off="Off"></span></label></div>';
        public static factory() {
            var directive = () => {
                return new ApxToggle();
            };
            return directive;
        }
    }
} 