///<reference path="./../_reference.ts"/>

module Apex.Components {
    export interface IApxTreeList {
        options?: Apex.Components.IApxTreeListOptions;
        treeList?: kendo.ui.TreeList;
        showLoading?(): void;
        hideLoading?(): void;
        Id?:string;
    }
}