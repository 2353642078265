/// <reference path='./../_reference.ts' />
module Apex.Components.Directives {
    "use strict";

    function toggleCardCollapse(card, duration) {

        duration = typeof duration !== 'undefined' ? duration : 400;
        var dispatched = false;
        card.find('.nano').slideToggle(duration);
        card.find('.card-body').slideToggle(duration, function () {
            if (!dispatched) {
                $('#COLLAPSER').triggerHandler('card.bb.collapse', [!$(this).is(":visible")]);
                dispatched = true;
            }
        });
        card.toggleClass('card-collapsed');
    }

    //function handleCardCollapse(e: any) {
    //    var card = $(e.currentTarget).closest('.card');
    //    toggleCardCollapse(card, 100);

    //    $("#page-head").trigger('heightChange');
    //};

    export class ApxFilter implements angular.IDirective {
        restrict = 'AE';
        transclude = true;
        replace = true;
        template = '<div class="card card-bordered style-primary" ng-transclude></div>';
        public static factory() {
            var directive = () => {
                return new ApxFilter();
            };
            return directive;
        }
    }

    export class ApxFilterHeader implements angular.IDirective {
        restrict = 'AE';
        transclude = true;
        replace = true;
        template = '<div class="card-head apx-filter-card" ng-transclude><div></div></div>';
        link(scope, element) {
           // scope.cardID = 'cardID' + scope.$id;
            //setTimeout(() => {
            //    $('#' + scope.cardID).on('click', e => {
            //        handleCardCollapse(e);
            //    });
            //    $('#' + scope.cardID).click();
            //}, 100);
            $("#page-head").trigger('heightChange');
        }

        public static factory() {
            var directive = () => {
                return new ApxFilterHeader();
            };
            return directive;
        }
    }

    export class ApxFilterContent implements angular.IDirective {
        restrict = 'AE';

        transclude = true;
        replace = true;
        template = '<div class="card-body"  ng-transclude></div>';

        public static factory() {
            var directive = () => {
                return new ApxFilterContent();
            };
            return directive;
        }
    }


} 