/// <reference path='./../_reference.ts' />
module Apex.Components.Directives {
    "use strict";

    export class ApxColOffset implements angular.IDirective {
        restrict = "A";
        link(scope, element, attrs, controller) {
            element.addClass('col-md-offset-' + attrs.apxColOffset + ' col-xs-offset-' + attrs.apxColOffset);
            element.removeAttr('apx-col-offset');
        }

        public static factory() {
            var directive = () => {
                return new ApxColOffset();
            };
            return directive;
        }
    }
} 