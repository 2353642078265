/// <reference path='./../_reference.ts' />
module Apex.Components.Controllers {
    export class ApxDatebetween extends Core.Controller {

        public static $inject = [
            Globals.scope, Globals.rootScope
        ];

        constructor(private $scope: any, private $rootScope: any) {

            super(arguments, ApxDatebetween.$inject);
            $scope.status = {
                isopen: false
            };
            $scope.changePeriond = this.changePeriond;

            if (typeof $scope.dateBetween.fromDate === "undefined")
                $scope.changePeriond('cMonth');


            $scope.$watch("dateBetween.fromDate", () => {
                if ($scope.dateBetween.fromDate > $scope.dateBetween.toDate) {
                    $scope.dateBetween.toDate = $scope.dateBetween.fromDate;
                }
            });
            $scope.$watch("dateBetween.toDate", () => {
                if ($scope.dateBetween.fromDate > $scope.dateBetween.toDate) {
                    $scope.dateBetween.fromDate = $scope.dateBetween.toDate;
                }
            });
        }

        changePeriond = (period: any, $event: any): void => {
            var fromDate = new Date();
            var toDate = new Date();
            var quarter;
            switch (period) {
                case 'cMonth':
                    fromDate.setDate(1);
                    toDate.setMonth(toDate.getMonth() + 1);
                    toDate.setDate(0);
                    break;
                case 'cQuartal':
                    quarter = Math.floor((fromDate.getMonth() + 3) / 3);
                    fromDate.setMonth((quarter - 1) * 3);
                    fromDate.setDate(1);
                    toDate.setMonth(quarter * 3);
                    toDate.setDate(0);
                    break;
                case 'cYear':
                    fromDate.setMonth(0);
                    fromDate.setDate(1);
                    toDate.setMonth(12);
                    toDate.setDate(0);
                    break;
                case 'pDay':
                    fromDate.setDate(fromDate.getDate() - 1);
                    toDate.setDate(toDate.getDate() - 1);
                    break;
                case 'fMonth':
                    fromDate.setDate(1);
                    break;
                case 'fQuartal':
                    quarter = Math.floor((fromDate.getMonth() + 3) / 3);
                    fromDate.setMonth((quarter - 1) * 3);
                    fromDate.setDate(1);
                    break;
                case 'fYear':
                    fromDate.setMonth(0);
                    fromDate.setDate(1);
                    break;
                case 'lMonth':
                    fromDate.setMonth(fromDate.getMonth() - 1);
                    fromDate.setDate(1);
                    toDate.setMonth(toDate.getMonth());
                    toDate.setDate(0);
                    break;
                case 'lDay':
                    fromDate.setDate(fromDate.getDate() - 1);
                    toDate.setDate(toDate.getDate() - 1);
                    break;
                case 'lQuartal':
                    quarter = Math.floor((fromDate.getMonth() + 3) / 3);
                    fromDate.setMonth((quarter - 2) * 3);
                    fromDate.setDate(1);
                    toDate.setMonth((quarter - 1) * 3);
                    toDate.setDate(0);
                    break;
                case 'lYear':
                    fromDate.setFullYear(fromDate.getFullYear() - 1);
                    fromDate.setMonth(0);
                    fromDate.setDate(1);
                    toDate.setFullYear(fromDate.getFullYear());
                    toDate.setMonth(12);
                    toDate.setDate(0);
                    break;
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                    fromDate.setDate(1);
                    fromDate.setMonth(period);
                    toDate.setMonth(period + 1);
                    toDate.setDate(0);
                    break;
            }

            this.$scope.dateBetween = {
                fromDate: fromDate,
                toDate: toDate
            }


            this.$scope.dateBetween.fromDate.setHours(0, 0, 0, 0);
            this.$scope.dateBetween.toDate.setHours(0, 0, 0, 0);

            if ($event) {
                $event.preventDefault();
                $event.stopPropagation();

                this.$scope.status.isopen = !this.$scope.status.isopen;
            }
        }
    }

}
