/// <reference path='./../_reference.ts' />
module Apex.Components.Directives {
    "use strict";

    export class ApxDontMutch implements angular.IDirective {
        restrict = "A";
        link = (scope, elm, attrs, ctrl) => {
            var apxDontMutch = '';
            
            if (typeof ctrl !== "undefined") {
                attrs.$observe('apxDontMutch', value => {
                    apxDontMutch = value;
                    ctrl.$setValidity('dontMutch', value !== ctrl.$modelValue);
                });
                ctrl.$validators.dontMutch = (modelValue) => {
                    return apxDontMutch !== modelValue;
                };
            }
        };

        public static factory() {
            var directive = () => {
                return new ApxDontMutch();
            };
            return directive;
        }
    }
}