/// <reference path='./../_reference.ts' />
module Apex.Components.Directives {
    "use strict";

    export class ApxPageHeader implements angular.IDirective {
        restrict = "AE";
        replace = true;
        transclude = true;
        templateUrl = 'apx.page.header.html';
        link(scope: angular.IScope, instanceElement: angular.IAugmentedJQuery, instanceAttributes: angular.IAttributes, controller, transclude: angular.ITranscludeFunction): void {


        }
        public static factory() {
            var directive = () => {
                return new ApxPageHeader();
            };
            return directive;
        }
    }
} 