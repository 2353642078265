/// <reference path='./../_reference.ts' />
module Apex.Components.Directives {
    "use strict";

    export class ApxInputLabel implements angular.IDirective {
        restrict = 'E';
        templateUrl = 'apx.input.label.html';
        replace = true;
        require = 'ngModel';
        transclude = true;
        scope = {
            ngModel: '='
        }
        constructor(private $compile, private $translate, private $rootScope) {

        }

        compile(tElement, tAttrs, transclude) {

            var compile = this.$compile;
            var rootScope = this.$rootScope;
            var translate = this.$translate;


            return {
                pre(scope, iElement, iAttrs) {
                    transclude(scope, clone => {
                        if (clone.length > 0) {
                            scope.labelName = translate.instant(clone[0].textContent);
                            rootScope.$on('$translateChangeSuccess', () => {
                                scope.labelName = translate.instant(clone[0].textContent);
                            });
                        }
                    });

                    var attributes = $(iElement).prop("attributes");
                    var input = $(iElement).find("input");
                    $.each(attributes, function (item) {
                        var name = this.name;
                        var val = this.value;
                        if (name !== "class" && name !== 'ng-model' && name !== 'apx-col-span' && name !== 'apx-col-offset') {
                            input.attr(name, val);
                        }
                    });

                    var dontRemovedAttr = [''];
                    Core.Utils.Helper.removeAttributes(iElement, dontRemovedAttr);
                    compile(iElement)(scope);

                },
                post(scope, iElement, iAttrs) {
                    var input = iElement.find('input');
                    scope.$watch('ngModel', () => {
                        if ($.trim(scope.ngModel) !== '') {
                            input.addClass('static').addClass('dirty');
                        } else {
                            input.removeClass('dirty').removeClass('static');
                        }
                    });

                    //input.on('change keyup',() => {
                   
                    //    if ($.trim(input.val()) !== '') {
                    //        input.addClass('dirty').removeClass('static');
                    //    } else {
                    //        input.removeClass('dirty').removeClass('static');
                    //    }
                    //});
                }
            }




        }

        public static factory() {
            var directive = ($compile, $translate, $rootScope) => {
                return new ApxInputLabel($compile, $translate, $rootScope);
            };

            directive["$inject"] = ['$compile', '$translate', '$rootScope'];

            return directive;
        }
    }
} 