///<reference path="./../_reference.ts"/>

module Apex.Components {
    export interface IApxGrid {
        addEditObjectModel?: any;
        isLookup?: boolean;
        columns?: Array<Core.Interfaces.IGridColumn>;
        templates?: Array<Core.Interfaces.IGridColumnTemplate>;
        settings?: Core.Kendo.IGridSettings;
        dataSource?: kendo.data.DataSource;
        grid?: kendo.ui.Grid;
        parentScope?: any;
        gridEvents?: Array<IEvent>;
        gridView?: IApxGridView;
        initCallback?: Function;
        selectedObject?: any;        
        gridId?:string;
        getSelectedOrCurrent?<T>():T[];
        SelectAll?():void;
        UnselectAll?():void;
    }

    export interface IApxGridView {
        grid?: kendo.ui.Grid;
        refresh(): void;
        setFilter(filterParams): void;
        refreshDatasource(): void;
        selectedItem(): any;
        showLoading(): void;
        hideLoading(): void;
    }

    export interface IEvent {
        eType?: EventType;
        eFunction?(e?: kendo.ui.GridEvent): void;
        customFunction?(e: any): any;
    }
    export enum EventType {
        Save, Cancel, DataBoud, Change, Edit, ExportToExcel, Delete, Click, ISelectedChange, None
    }
}
