/// <reference path='./../_reference.ts' />

module Apex.Components.Directives {
    "use strict";


    export interface IDropdownScope extends angular.IScope {
        options: Components.IApxDropdown;
        apxOptions: kendo.ui.DropDownListOptions;
    }

    export class ApxDropd implements angular.IDirective {
        restrict = 'E';
        replace = true;
        scope = {
            options: '='
        };
        templateUrl = 'apx.dropd.html';

        constructor(private $compile) { }
        compile = () => {
            var compile = this.$compile;
            return {
                pre(scope: IDropdownScope, element) {
                    if (typeof scope.options === "undefined") {
                        throw Error("options is requered!");
                    }
                    var attributes = $(element).prop("attributes");
                    var selectInput = $(element).find("select");
                    var ignoreAttributes = ['class', 'apx-col-span', 'apx-col-offset', 'apx-dont-mutch', 'options'];
                    _.each(attributes, (item: { name; value; }) => {
                        if (!_.contains(ignoreAttributes, item.name)) {
                            selectInput.attr(item.name, item.value);
                        }
                    });
                    Core.Utils.Helper.removeAttributes(element, ['apx-dont-mutch']);
                    compile(element)(scope);
                },
                post(scope) {

                    scope.apxOptions = {
                        dataValueField: scope.options.valueID,
                        dataTextField: scope.options.valueText,
                        filter: 'contains',
                        dataSource: scope.options.dataSource
                    };

                   

                    scope.$on('$destroy', () => { kendo.destroy($("#apxDropDown-" + scope.$id + "-list")); });

                   
                    //var isLoaded = false;
                    //if (!!scope.apxDatasource) {
                    //    if (scope.apxDatasource instanceof kendo.data.DataSource) {
                    //        scope.dropdownOptions.dataSource = scope.apxDatasource;
                    //        if (scope.ngModel !== undefined) {

                    //            scope.dropdownOptions.dataSource.filter({ field: scope.dropdownOptions.dataValueField, operator: "startswith", value: scope.ngModel });
                    //        }
                    //    } else {

                    //        scope.dropdownOptions.dataSource = Core.Kendo.GridView.dataToDataSource(scope.apxDatasource);
                    //    }

                    //} else {

                    //    scope.dropdownOptions.dataSource = new kendo.data.DataSource(null);
                    //}

                    //function refreshDropdown() {
                    //    var id = '#apxDropDown-' + scope.$id;
                    //    var dropDown = $(id).data("kendoDropDownList");

                    //    if (typeof dropDown === "undefined") {
                    //        setTimeout(() => {
                    //            refreshDropdown();
                    //            return;
                    //        }, 300);
                    //    }

                    //    if (typeof dropDown !== "undefined" && dropDown !== null) {

                    //        if (scope.apxDatasource instanceof kendo.data.DataSource) {
                    //            scope.apxDatasource.read();


                    //        } else {
                    //            dropDown.dataSource.data(scope.apxDatasource);
                    //            dropDown.refresh();
                    //        }
                    //    }

                    //}

                    //scope.$watch('apxDatasource', (val) => {
                    //    if (typeof val === "undefined" || val === null) return;
                    //    refreshDropdown();
                    //});

                    //scope.onOpen = () => {
                    //    if (isLoaded) return;
                    //    if (typeof scope.apxDatasource === "undefined" || scope.apxDatasource === null || scope.apxDatasource.length < 2) {
                    //        scope.$apply(scope.apxRefreshDs);
                    //    }
                    //    isLoaded = true;
                    //};
                    //scope.$on('$destroy', () => { kendo.destroy($("#apxDropDown-" + scope.$id + "-list")); });
                }
            }
        }
        public static factory() {
            var directive = ($compile) => {
                return new ApxDropd($compile);
            };
            directive['$inject'] = ['$compile'];
            return directive;
        }
    }
}