/// <reference path='./../_reference.ts' />
module Apex.Components.Directives {
    "use strict";
    export class ApxLabel implements angular.IDirective {
        restrict = 'E';
        replace = true;
        transclude = true;
        scope = {};
        template = "<div><label class='control-label apx-padding-2 text-right pull-right' ng-class=\"{'pull-left': isLeft}\" translate='{{transcluded_content}}'></label> </div>";
        compile(tElement, tAttrs, transclude) {
            return {
                pre(scope) {
                    transclude(scope, clone => {
                        scope.transcluded_content = clone[0].textContent;
                    });
                    if (typeof tAttrs.labelLeft !== "undefined") {
                        scope.isLeft = true;
                    }
                }
            }
        }

        public static factory() {
            var directive = () => {
                return new ApxLabel();
            };
            return directive;
        }
    }

} 