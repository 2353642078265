/// <reference path='./../_reference.ts' />
module Apex.Components.Directives {
    "use strict";
    export class ApxTextbox implements angular.IDirective {
        restrict: string;
        templateUrl: any;

        replace: boolean;

        require: any;
        scope: any;
        template: any;
        transclude: any;
        link(scope: angular.IScope, instanceElement: angular.IAugmentedJQuery, instanceAttributes: angular.IAttributes,
            controller, transclude: angular.ITranscludeFunction): void { }


        constructor(private $compile) {
            this.restrict = 'E';
            this.replace = true;
            this.require = 'ngModel';
            this.templateUrl = 'apx.textbox.html',

                this.link = (scope: any, element: angular.IAugmentedJQuery, attrs: any) => {
                    var attributes = $(element).prop("attributes");
                    var input = $(element).find("input");

                    if (typeof attrs.apxTextRight !== "undefined")
                        input.addClass('apx-text-right');

                    if (typeof attrs.password !== "undefined") {
                        scope.type = 'password';
                    }
                    else if (typeof attrs.datetime !== "undefined")
                        scope.type = 'datetime';
                    else  scope.type = 'text';
                    if (typeof attrs.time !== "undefined"){
                        scope.type = 'time';
                        // input.addClass("without");

                    } else



                    $.each(attributes, function () {
                        var name = this.name;
                        var val = this.value;

                        if (name !== "class" && name !== 'apx-col-span' && name !== 'apx-col-offset') {
                            input.attr(name, val);
                        } else if (name === "class" && val.indexOf('apx-number') !== -1) {
                            input.addClass('apx-number');
                        }
                      

                    });
                    var dontRemovedAttr = ['class'];
                    Core.Utils.Helper.removeAttributes(element, dontRemovedAttr);

                    this.$compile(element)(scope);
                };
        }
        public static factory() {
            var directive = ($compile) => {
                return new ApxTextbox($compile);
            };

            directive['$inject'] = ['$compile'];

            return directive;
        }
    }
} 