///<reference path="./../_reference.ts"/>

module Apex.Components {

    export interface IApxDropdown {
        dataSource?: kendo.data.DataSource;
        valueID: string;
        valueText: string;
        refresh?: void;
    }
}
