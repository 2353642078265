/// <reference path='./../_reference.ts' />
module Apex.Components.Directives {

    "use strict";

    export class ApxExportGrid implements angular.IDirective {

        restrict = "E";
        templateUrl = 'apx.export.excel.html';
        scope = {
            apxKGrid: '='
        };

        controller = Components.Controllers.ExportGrid;

        link(scope, element) {
         
        }

        public static factory() {
            var directive = () => {
                return new ApxExportGrid();
            };
            return directive;
        }
    }
} 