/// <reference path='./../_reference.ts' />
module Apex.Components.Directives {
    "use strict";

    export class ApxDate implements angular.IDirective {
        restrict = 'E';
        replace = true;
        scope = {
            dateTime: '=ngModel',
            ngDisabled: '=',
            eventOnChange: '=?',
            nullable: '='
        };
        require = ['ngModel'];
        templateUrl = 'apx.date.html';
        controller = Components.Controllers.ApxDate.getName();
        link = (scope, element: any, attr) => {            
            scope.id = "apxDate-" + scope.$id;

            var date = element.find("input");
            var lastKeyCode;
            var currentDateSelected;
            var keyDown = false;
            var changeSelection = (type, element) => {
                switch (type) {
                    case 'day':
                        element.selectionStart = 0;
                        element.selectionEnd = 1;
                        break;
                    case 'month':
                        element.selectionStart = 3;
                        element.selectionEnd = 4;
                        break;
                    case 'year':
                        element.selectionStart = 6;
                        element.selectionEnd = 7;
                        break;
                }

            };

            var keydownEvent = (e) => {
                lastKeyCode = e.which;
                keyDown = true;
                if (scope.eventOnChange != undefined) {
                    scope.eventOnChange(e);
                }
                switch (lastKeyCode) {
                    case 38:
                    case 40:
                        e.preventDefault();
                        break;
                    case 37:
                        e.preventDefault();
                        if (currentDateSelected === 1) {
                            changeSelection("day", document.getElementById(scope.id));
                        } else if (currentDateSelected === 2) {
                            changeSelection("month", document.getElementById(scope.id));
                        }
                        keyDown = false;
                        break;
                    case 39:
                        e.preventDefault();
                        if (currentDateSelected === 0) {
                            changeSelection("month", document.getElementById(scope.id));
                        } else if (currentDateSelected === 1) {
                            changeSelection("year", document.getElementById(scope.id));
                        }
                        keyDown = false;
                        break;
                }
            }

            var focusOutEvent = () => {
                if (!($(element).is("[nullable]") && (scope.dateTime === null || scope.dateTime === undefined)) && !_.isDate(scope.dateTime)) {
                    Core.Utils.Helper.safeApply(scope, scope.dateTime = new Date());
                }
                lastKeyCode = '';
                currentDateSelected = '';
                keyDown = false;
            }
            var selectionEvent = (e) => {
                var el: any = document.getElementById(scope.id);
                if (el.selectionStart < 2) {
                    if ((el.selectionStart !== 0 && el.selectionEnd !== 1) && !keyDown)
                        changeSelection("day", el);

                    currentDateSelected = 0;
                }
                else if (el.selectionStart > 1 && el.selectionStart < 5) {
                    if ((el.selectionStart !== 3 || el.selectionEnd !== 4) && !keyDown)
                        changeSelection("month", el);

                    currentDateSelected = 1;
                } else if (el.selectionStart > 4) {
                    if ((el.selectionStart !== 6 || el.selectionEnd !== 7) && !keyDown)
                        changeSelection("year", el);

                    currentDateSelected = 2;
                }

            }
            date.on('change', () => {
                if (scope.eventOnChange != undefined) {
                    scope.eventOnChange();
                }
            });

            date.on('keydown', keydownEvent);
            date.on('click', () => {
                keyDown = false;
            });

            date.inputmask('dd/mm/yyyy', { "placeholder": "__/__/____" });

            var initByDefoult = $(element).attr('preventDefaultValueOnFocusOut');

            if (initByDefoult != undefined) {
                date.off('focusout');
                date.on('blur', () => {
                    date.trigger(jQuery.Event("keydown", { which: 13, keyCode: 13 }));
                });
            } else {
                date.focusout(focusOutEvent);
            }

            date.on("select", selectionEvent);
        }

        public static factory() {
            var directive = () => {
                var apxDate = new ApxDate();
                return apxDate;
            };
            return directive;
        }
    }

} 