/// <reference path='./../_reference.ts' />
module Apex.Components.Directives {
    "use strict";

    export class ApxCircleButton implements angular.IDirective {
        restrict = "AE";
        replace = true;
        transclude = true;
        scope = {
            apxFaIcon: '@',
            apxColor: '@',
            title: '@'
        }
        templateUrl = 'apx.button.a.html';
        link = (scope: any, element: angular.IAugmentedJQuery, attrs: angular.IAttributes, ctrl, transclude) => {
            transclude(scope, clone => {
                if (clone.length > 0) {
                    if (clone[0].textContent.trim()) {
                        scope.title = this.$translate.instant(clone[0].textContent.trim());
                        this.$rootScope.$on('$translateChangeSuccess', () => {
                            scope.title = this.$translate.instant(clone[0].textContent.trim());
                        });
                    } else if (scope.title.trim()) {
                        scope.title = this.$translate.instant(scope.title.trim());
                        this.$rootScope.$on('$translateChangeSuccess', () => {
                            scope.title = this.$translate.instant(scope.title.trim());
                        });
                    }
                }
            });
        };

        constructor(private $translate, private $rootScope) {
        }

        public static factory() {

            var directive = ($translate, $rootScope) => {
                return new ApxCircleButton($translate, $rootScope);
            };
            directive['$inject'] = ['$translate', '$rootScope'];

            return directive;
        }
    }


    export class ApxButtonAdd implements angular.IDirective {
        restrict = "E";
        replace = true;
        templateUrl = 'apx.button.a.html';
        scope = {};
        link = (scope: any, element: angular.IAugmentedJQuery, attrs: angular.IAttributes, ctrl, transclude) => {
            scope.apxColor = 'success';
            scope.apxFaIcon = 'plus';
            scope.title = this.$translate.instant('Add');
            this.$rootScope.$on('$translateChangeSuccess', () => {
                scope.title = this.$translate.instant('Add');
            });

            this.hotkeys.add({
                combo: 'ctrl+n',
                callback() {
                    setTimeout(() => { element.trigger('click'); });
                }
            });

            scope.$on('$destroy', () => { this.hotkeys.del('ctrl+alt+n'); });
        };

        constructor(private $translate, private $rootScope, private hotkeys) {

        }

        public static factory() {

            var directive = ($translate, $rootScope, hotkeys) => {
                return new ApxButtonAdd($translate, $rootScope, hotkeys);
            };
            directive['$inject'] = ['$translate', '$rootScope', 'hotkeys'];

            return directive;
        }
    }

    export class ApxButtonEdit implements angular.IDirective {
        restrict = "E";
        replace = true;
        templateUrl = 'apx.button.a.html';
        scope = {};
        link = (scope: any, element: angular.IAugmentedJQuery, attrs: angular.IAttributes, ctrl, transclude) => {
            scope.apxColor = 'warning';
            scope.apxFaIcon = 'pencil';
            scope.title = this.$translate.instant('Edit');
            this.$rootScope.$on('$translateChangeSuccess', () => {
                scope.title = this.$translate.instant('Edit');
            });

            this.hotkeys.add({
                combo: 'ctrl+alt+e',
                callback() {
                    setTimeout(() => { element.trigger('click'); });
                }
            });

            element.css("width", "80px");

            scope.$on('$destroy', () => { this.hotkeys.del('ctrl+alt+e'); });
        };

        constructor(private $translate, private $rootScope, private hotkeys) {

        }
        public static factory() {

            var directive = ($translate, $rootScope, hotkeys) => {
                return new ApxButtonEdit($translate, $rootScope, hotkeys);
            };
            directive['$inject'] = ['$translate', '$rootScope', 'hotkeys'];

            return directive;
        }
    }


    export class ApxButtonView implements angular.IDirective {
        restrict = "E";
        replace = true;
        templateUrl = 'apx.button.a.html';
        scope = {};
        link = (scope: any, element: angular.IAugmentedJQuery, attrs: angular.IAttributes, ctrl, transclude) => {
            scope.apxColor = 'warning';
            scope.apxFaIcon = 'eye';
            scope.title = this.$translate.instant('view');
            this.$rootScope.$on('$translateChangeSuccess', () => {
                scope.title = this.$translate.instant('Edit');
            });
            this.hotkeys.add({
                combo: 'ctrl+alt+e',
                callback() {
                    setTimeout(() => { element.trigger('click'); });
                }
            });

            element.css("width", "80px");

            scope.$on('$destroy', () => { this.hotkeys.del('ctrl+alt+e'); });
        };

        constructor(private $translate, private $rootScope, private hotkeys) {

        }
        public static factory() {

            var directive = ($translate, $rootScope, hotkeys) => {
                return new ApxButtonView($translate, $rootScope, hotkeys);
            };
            directive['$inject'] = ['$translate', '$rootScope', 'hotkeys'];

            return directive;
        }
    }


    export class ApxButtonCopy implements angular.IDirective {

        restrict = "E";
        replace = true;
        templateUrl = 'apx.button.a.html';
        scope = {};
        link = (scope: any, element: angular.IAugmentedJQuery, attrs: angular.IAttributes, ctrl, transclude) => {
            scope.apxColor = 'info';
            scope.apxFaIcon = 'copy';
            scope.title = this.$translate.instant('Copy');
            this.$rootScope.$on('$translateChangeSuccess', () => {
                scope.title = this.$translate.instant('Copy');
            });

            this.hotkeys.bindTo(scope).add({
                combo: 'ctrl+alt+c',
                callback() {
                    setTimeout(() => { element.trigger('click'); });
                }
            });
            scope.$on('$destroy', () => { this.hotkeys.del('ctrl+alt+c'); });
        };

        constructor(private $translate, private $rootScope, private hotkeys) {

        }

        public static factory() {

            var directive = ($translate, $rootScope, hotkeys) => {
                return new ApxButtonCopy($translate, $rootScope, hotkeys);
            };
            directive['$inject'] = ['$translate', '$rootScope', 'hotkeys'];

            return directive;
        }
    }

    export class ApxButtonRefresh implements angular.IDirective {

        restrict = "E";
        replace = true;
        template = '<button type="button" class="btn ink-reaction apx-button1">' +
        '<i class="fa fa-refresh text-info"></i>' +
        '<icon ng-if="showInfo" class="fa fa-hand-o-right text-danger animation-blink" style="top: 0;left: 0;position: absolute;font-weight: 700;"></icon>' +
        '<span>{{title}}</span>' +
        '</button>';
        scope = {
            showInfo: "="
        };
        link = (scope: any, element: angular.IAugmentedJQuery, attrs: angular.IAttributes, ctrl, transclude) => {

            scope.title = this.$translate.instant('Refresh');
            this.$rootScope.$on('$translateChangeSuccess', () => {
                scope.title = this.$translate.instant('Refresh');
            });
            this.hotkeys.bindTo(scope).add({
                combo: 'ctrl+alt+r',
                callback() {
                    setTimeout(() => { element.trigger('click'); });
                }
            });
            scope.$on('$destroy', () => { this.hotkeys.del('ctrl+alt+r'); });
        };

        constructor(private $translate, private $rootScope, private hotkeys) {

        }

        public static factory() {

            var directive = ($translate, $rootScope, hotkeys) => {
                return new ApxButtonRefresh($translate, $rootScope, hotkeys);
            };
            directive['$inject'] = ['$translate', '$rootScope', 'hotkeys'];

            return directive;
        }
    }

    export class ApxButtonDelete implements angular.IDirective {
        restrict = "E";
        replace = true;
        scope = {};
        templateUrl = 'apx.button.a.html';
        link = (scope: any, element: angular.IAugmentedJQuery, attrs: angular.IAttributes, ctrl, transclude) => {
            scope.apxColor = 'danger';
            scope.apxFaIcon = 'remove';

            scope.title = this.$translate.instant('Delete');
            this.$rootScope.$on('$translateChangeSuccess', () => {
                scope.title = this.$translate.instant('Delete');
            });


            this.hotkeys.bindTo(scope).add({
                combo: 'ctrl+alt+d',
                callback() {
                    setTimeout(() => { element.trigger('click'); });
                }
            });
            scope.$on('$destroy', () => { this.hotkeys.del('ctrl+alt+d'); });
        };

        constructor(private $translate, private $rootScope, private hotkeys) {

        }

        public static factory() {

            var directive = ($translate, $rootScope, hotkeys) => {
                return new ApxButtonDelete($translate, $rootScope, hotkeys);
            };
            directive['$inject'] = ['$translate', '$rootScope', 'hotkeys'];

            return directive;
        }
    }

    export class ApxButtonFilter implements angular.IDirective {
        restrict = "E";
        replace = true;
        templateUrl = 'apx.button.a.html';
        scope = {};
        link = (scope: any, element: angular.IAugmentedJQuery, attrs: angular.IAttributes, ctrl, transclude) => {
            scope.apxColor = 'accent';
            scope.apxFaIcon = 'filter';
            scope.title = this.$translate.instant('FILTER');
            this.$rootScope.$on('$translateChangeSuccess', () => {
                scope.title = this.$translate.instant('FILTER');
            });

            this.hotkeys.add({
                combo: 'ctrl+alt+f',
                callback() {
                    setTimeout(() => { element.trigger('click'); });
                }
            });

            scope.$on('$destroy', () => { this.hotkeys.del('ctrl+alt+f'); });
        };
        constructor(private $translate, private $rootScope, private hotkeys) {

        }

        public static factory() {

            var directive = ($translate, $rootScope, hotkeys) => {
                return new ApxButtonFilter($translate, $rootScope, hotkeys);
            };
            directive['$inject'] = ['$translate', '$rootScope', 'hotkeys'];

            return directive;
        }
    }

    export class ApxButtonDownload implements angular.IDirective {
        restrict = "E";
        replace = true;
        templateUrl = 'apx.button.a.html';
        scope = {};
        link = (scope: any, element: angular.IAugmentedJQuery, attrs: angular.IAttributes, ctrl, transclude) => {
            scope.apxColor = 'accent';
            scope.apxFaIcon = 'download';
            scope.title = this.$translate.instant('download');
            this.$rootScope.$on('$translateChangeSuccess', () => {
                scope.title = this.$translate.instant('download');
            });
            // this.hotkeys.add({
            //     combo: 'ctrl+alt+f',
            //     callback() {
            //         setTimeout(() => { element.trigger('click'); });
            //     }
            // });

            // scope.$on('$destroy', () => { this.hotkeys.del('ctrl+alt+f'); });
        };
        constructor(private $translate, private $rootScope, private hotkeys) {

        }

        public static factory() {

            var directive = ($translate, $rootScope, hotkeys) => {
                return new ApxButtonDownload($translate, $rootScope, hotkeys);
            };
            directive['$inject'] = ['$translate', '$rootScope', 'hotkeys'];

            return directive;
        }
    }
    
    export class ApxButtonUpload implements angular.IDirective {
        restrict = "E";
        replace = true;
        templateUrl = 'apx.button.a.html';
        scope = {};
        link = (scope: any, element: angular.IAugmentedJQuery, attrs: angular.IAttributes, ctrl, transclude) => {
            scope.apxColor = 'accent';
            scope.apxFaIcon = 'upload';
            scope.title = this.$translate.instant('upload');
            this.$rootScope.$on('$translateChangeSuccess', () => {
                scope.title = this.$translate.instant('upload');
            });
            // this.hotkeys.add({
            //     combo: 'ctrl+alt+f',
            //     callback() {
            //         setTimeout(() => { element.trigger('click'); });
            //     }
            // });
            // scope.$on('$destroy', () => { this.hotkeys.del('ctrl+alt+f'); });
        };
        constructor(private $translate, private $rootScope, private hotkeys) {

        }

        public static factory() {

            var directive = ($translate, $rootScope, hotkeys) => {
                return new ApxButtonUpload($translate, $rootScope, hotkeys);
            };
            directive['$inject'] = ['$translate', '$rootScope', 'hotkeys'];

            return directive;
        }
    }
}  