///<reference path="./../_reference.ts"/>

module Apex.Components {
    export interface IApxLookup {
        columns: Array<Core.Interfaces.IGridColumn>;
        columTemplates?: Array<Core.Interfaces.IGridColumnTemplate>;
        dataSource?: kendo.data.DataSource;
        valueID?: string;
        ngModel?: any;
        valueText: string;
        openEvent?: Function;
        value?:any;
    }
}
