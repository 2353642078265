/// <reference path='./../_reference.ts' />
module Apex.Components.Directives {
    "use strict";

    export class ApxPrintHtml implements angular.IDirective {
        restrict = "A";

        link(scope, element, attrs) {
            function printElement(elem) {
                var domClone = elem.cloneNode(true);
                var $printSection = document.getElementById("printSection");
                if (!$printSection) {
                    $printSection = document.createElement("div");
                    $printSection.id = "printSection";
                    document.body.appendChild($printSection);
                }

                $printSection.innerHTML = "";

                $printSection.appendChild(domClone);
            }

            element.bind('click', evt => {
                printElement(document.getElementById(attrs.apxPrintHtml));
                window.print();
                $("#printSection").remove();
            });
        }
        public static factory() {
            var directive = () => {
                return new ApxPrintHtml();
            };

            return directive;
        }
    }
} 