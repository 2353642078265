///<reference path="./../_reference.ts"/>

module Apex.Components {
    export interface IApxTreeListOptions {
        name?: string;
        autoBind?: boolean;
        columns?: Apex.Core.Interfaces.ITreeListColumn[];
        resizable?: boolean;
        reorderable?: boolean;
        columnMenu?: kendo.ui.TreeListColumnMenu;
        dataSource?: any;
        editable?: kendo.ui.TreeListEditable;
        excel?: kendo.ui.TreeListExcel;
        height?: any;
        messages?: kendo.ui.TreeListMessages;
        pdf?: kendo.ui.TreeListPdf;
        scrollable?: any;
        selectable?: any;
        filterable?: boolean | kendo.ui.TreeListFilterable;
        sortable?: kendo.ui.TreeListSortable;
        toolbar?: kendo.ui.TreeListToolbarItem[];
        cancel?(e: kendo.ui.TreeListCancelEvent): void;
        change?(e: kendo.ui.TreeListChangeEvent): void;
        collapse?(e: kendo.ui.TreeListCollapseEvent): void;
        dataBinding?(e: kendo.ui.TreeListDataBindingEvent): void;
        dataBound?(e: kendo.ui.TreeListDataBoundEvent): void;
        edit?(e: kendo.ui.TreeListEditEvent): void;
        excelExport?(e: kendo.ui.TreeListExcelExportEvent): void;
        expand?(e: kendo.ui.TreeListExpandEvent): void;
        filterMenuInit?(e: kendo.ui.TreeListFilterMenuInitEvent): void;
        pdfExport?(e: kendo.ui.TreeListPdfExportEvent): void;
        remove?(e: kendo.ui.TreeListRemoveEvent): void;
        save?(e: kendo.ui.TreeListSaveEvent): void;
        columnShow?(e: kendo.ui.TreeListColumnShowEvent): void;
        columnHide?(e: kendo.ui.TreeListColumnHideEvent): void;
        columnReorder?(e: kendo.ui.TreeListColumnReorderEvent): void;
        columnResize?(e: kendo.ui.TreeListColumnResizeEvent): void;
        columnMenuInit?(e: kendo.ui.TreeListColumnMenuInitEvent): void;
        columnLock?(e: kendo.ui.TreeListColumnLockEvent): void;
        columnUnlock?(e: kendo.ui.TreeListColumnUnlockEvent): void;
    }
    export interface IApxTreeListFilterable {
        extra?: boolean;
        operator?: string;
    }
}