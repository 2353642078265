/// <reference path='./../_reference.ts' />
module Apex.Components.Directives {
    export class ApxDateTime implements angular.IDirective {
        restrict = 'E';
        replace = true;
        scope = {
            dateTime: '=ngModel',
            ngDisabled: '=',
            eventOnChange: '=?'
        };
        require = ['ngModel'];
        templateUrl = 'apx.datetime.html';
        controller = Components.Controllers.ApxDate.getName();

        link = (scope, element: any, attr) => {
            scope.id = "apxDate-" + scope.$id;

            var date = element.find("input");
            // if(!_.isDate(scope.dateTime)) {
            //     Core.Utils.Helper.safeApply(scope, () => { scope.dateTime = new Date(); alert(1);});
            // }
            
            date.on('keydown', () => {
                if (scope.eventOnChange != undefined) {
                    scope.eventOnChange();
                }
            });
            date.on('change', () => {
                if (scope.eventOnChange != undefined) {
                    scope.eventOnChange();
                }
            });

            date.inputmask('datetime', { "placeholder": "__/__/____ __:__" });

        }

        public static factory() {
            var directive = () => {
                var apxDate = new ApxDateTime();
                return apxDate;
            };
            return directive;
        }

    }
}