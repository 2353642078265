/// <reference path='./../_reference.ts' />
module Apex.Components.Services {
    'use strict';
    export class Components extends Core.Service {
        exportToExcel(url) {
            return this.getDataToExport(url).then(result => {
                return result;
            });
        }
    }
} 