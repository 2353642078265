/// <reference path='./../_reference.ts' />
module Apex.Components.Directives {
    "use strict";

    export class ApxMultiselect implements angular.IDirective {
        restrict = "AE";
        replace = true;
        require = ['ngModel'];
        scope = {
            ngModel: '=',
            ngDisabled: '=',
            apxDatasource: '=',
            preventClose: '=?'
        };
        templateUrl = 'apx.multiselect.html';
        link = (scope: any, element: any, attrs: any) => {
            var scopeId = scope.$id;
            scope.$watch('apxDatasource',(val) => {

                if (val === undefined) return;


                if (typeof attrs.apxValueId === "undefined" || typeof attrs.apxValueText === "undefined") {
                    throw Error('apx-value-id and apx-value-text is requered');
                }

                scope.multiselectOptions = {
                    filter: "contains",
                    dataValueField: attrs.apxValueId,
                    clearButton: true,
                    dataTextField: attrs.apxValueText,
                    valuePrimitive: true,
                    dataSource: new kendo.data.ObservableArray(scope.apxDatasource)
                };
                if (scope.preventClose != undefined && scope.preventClose == true) {
                    scope.multiselectOptions.autoClose = false;
                }

                if(typeof attrs.apxPlaceholder !== 'undefined') {
                    scope.multiselectOptions.placeholder =  this.$translate.instant(attrs.apxPlaceholder);
                }

            });
            var updateplaceHolder = () => {
                if (scope.multiselectOptions != undefined && scope.multiselectOptions.multSelect != undefined && scope.multiselectOptions.multSelect.options != undefined) {
                    scope.multiselectOptions.multSelect.options.placeholder = this.$translate.instant(attrs.apxPlaceholder);
                    scope.multiselectOptions.multSelect._placeholder();
                }
            };

            if(typeof attrs.apxPlaceholder !== 'undefined'){

                this.$rootScope.$on('$translateChangeSuccess', () => {
                    updateplaceHolder();
                });
            }

            scope.$on('$destroy',() => {
                 kendo.destroy($("#apxMultiselect-" + scopeId + "-list"));
                 
            });
        };

        constructor(private $translate, private $rootScope) {
        }

        public static factory() {
            var directive = ($translate, $rootScope) => {
                return new ApxMultiselect($translate, $rootScope);
            };
            directive['$inject'] = ['$translate', '$rootScope'];

            return directive;
        }
    }
} 