/// <reference path='./../_reference.ts' />
module Apex.Components.Directives {
    "use strict";
    export interface ISelectedEvent {
        object: any;
        oldValue: boolean;
        newValue: boolean;
        AllowToChange: boolean;
    }

    export interface IGridScope extends angular.IScope {
        options: Components.IApxGrid;
        apxGridOptions: Core.Interfaces.IGridOptions;
        popupModel?: any;
        loadingVisibled: boolean;
        iSelectedChange: Function;
        Select(rawData: any, value: boolean);

    }
    export class ApxGrid implements angular.IDirective {
        restrict = 'AE';
        templateUrl = 'apx.grid.html';
        replace = true;
        scope = {
            options: "="
        }
        constructor(private $compile) { }
        compile = () => {
            var compile = this.$compile;
            return {
                pre(scope, element, attr) {
                    if (typeof attr.options === "undefined") {
                        throw Error("options is requered!");
                    }
                    var attributes = $(element).prop("attributes");
                    var kendoGrid = $(element).find("div");
                    var ignoreAttributes = ['class', 'apx-col-span', 'apx-col-offset'];
                    _.each(attributes, (item: { name; value; }) => {
                        if (!_.contains(ignoreAttributes, item.name)) {
                            kendoGrid.attr(item.name, item.value);
                        }
                    });
                    Core.Utils.Helper.removeAttributes(element, []);
                    compile(element)(scope);
                },
                post(scope: IGridScope, element) {
                    
                    var $timeout = Core.Utils.Helper.getServiceFromJs(Globals.$timeout);
                    $(element).prepend('<apx-loading visibled="loadingVisibled" style="opacity: 0.7;"></apx-loading>');
                    scope.loadingVisibled = false;
                    compile($(element).find("apx-loading"))(scope);

                    var options = Core.Kendo.GridView.initGridOptions(scope.options.columns, scope.options.templates, scope.options.settings);
                    scope.options.gridId="apx-grid-"+scope.$id;
                    
                    scope.apxGridOptions = options;
                    if (scope.options.settings !== undefined && scope.options.settings.editble != null && !scope.options.settings.editble.disableEditRow) {
                        element.addClass("apx-editable");
                    }

                    var editablePopup = (e) => {
                        var width = 800;
                        var settings = scope.options.settings;
                        if (settings !== undefined && settings.editble !== undefined && settings.editble.popupWidth !== undefined)
                            width = settings.editble.popupWidth;

                        var popupWindow: kendo.ui.Window = $(e.container).width(width).data("kendoWindow");
                        if (popupWindow != undefined)
                            popupWindow.center();
                    };
                    scope.apxGridOptions.edit = editablePopup;

                    $timeout(() => {
                        $(element).find('.k-grid-delete').addClass("k-state-disabled");
                    });

                    scope.options.getSelectedOrCurrent = <T>(): T[] => {
                        let data = scope.options.grid.dataSource.data();
                        var selectedItems = [];
                        if (data != undefined && data.length > 0) {
                            if (data[0].isSelected != undefined) {
                                selectedItems = _.filter(data, (item) => { return item.isSelected == true });
                                if (selectedItems.length == 0) {
                                    var item = scope.options.gridView.selectedItem();
                                    if (item)
                                        selectedItems.push(item);
                                }
                            } else {
                                var selectedItem = scope.options.gridView.selectedItem();
                                if (selectedItem && selectedItem.length == 1)
                                    selectedItems.push(selectedItem);
                            }
                        }
                        return selectedItems;
                    }

                    scope.apxGridOptions.change = (e) => {
                        var selecteditem = scope.options.gridView.selectedItem();
                        if (selecteditem != null) {
                            $(element).find('.k-grid-delete').removeClass("k-state-disabled");
                            scope.options.selectedObject = selecteditem;
                        }
                        else {
                            $(element).find('.k-grid-delete').addClass("k-state-disabled");
                            scope.options.selectedObject = null;
                        }

                        if (scope.options.settings !== undefined && scope.options.settings.selectable == 'multiple') {
                            if (scope.options.gridView.selectedItem() != null && scope.options.grid.select().find(".k-edit-cell").length == 0) {
                                scope.options.grid.table.focus();
                            }
                        }
                        _.each(scope.options.gridEvents, (item: Components.IEvent) => {
                            if (item.eType == EventType.Change) {
                                item.eFunction(e);
                            }
                            return;
                        });
                    };
                    var datasourceId;

                    var lastindex;
                    scope.apxGridOptions.save = (e) => {
                        lastindex = {
                            row: scope.options.grid.tbody.children().index(e.container.parent()),
                            col: e.container.parent().children().index(e.container)
                        }

                        _.each(scope.options.gridEvents, (item: Components.IEvent) => {
                            if (item.eType == EventType.Save) {
                                item.eFunction(e);
                            }
                            return;
                        });
                    }

                    scope.apxGridOptions.dataBound = (e) => {
                        if (scope.loadingVisibled) {
                            Core.Utils.Helper.safeApply(scope, () => { scope.loadingVisibled = false; });
                        }
                        var grid = scope.options.grid;


                        if (!scope.options.isLookup && scope.options.selectedObject != undefined && scope.options.selectedObject != null) {
                            if (datasourceId != undefined) {
                                var isSelected = false;
                                var pageData = grid.dataSource.data();
                                for (var i = 0; i < pageData.length; i++) {
                                    if (Core.Utils.Helper.getDeepPropertyValue(pageData[i], datasourceId) == Core.Utils.Helper.getDeepPropertyValue(scope.options.selectedObject, datasourceId)) {
                                        $(element).find("[data-uid='" + pageData[i].uid + "']").addClass('k-state-selected');
                                        scope.options.selectedObject = grid.dataItem(grid.select());
                                        isSelected = true;
                                        //grid.element.find(".k-grid-content").animate({
                                        //    scrollTop: grid.select().position().top
                                        //    - grid.element.find('.k-grid-content').position().top
                                        //    + grid.element.find('.k-grid-content').scrollTop()
                                        //});
                                        break;
                                    }
                                }

                                if (!isSelected) {
                                    scope.options.grid.clearSelection();
                                }
                            }

                            if (!$.isEmptyObject(lastindex)) {
                                var cell = grid.tbody.children().eq(lastindex.row).children().eq(lastindex.col);
                                grid.current(cell);
                                grid.table.focus();
                            }
                        }

                        _.each(scope.options.gridEvents, (item: Components.IEvent) => {
                            if (item.eType == EventType.DataBoud) {
                                item.eFunction(e);
                            }
                            return;
                        });


                    };

                    if (typeof scope.options.gridEvents !== "undefined") {
                        _.each(scope.options.gridEvents, (item: Components.IEvent) => {
                            switch (item.eType) {
                                case EventType.ISelectedChange: {
                                    scope.iSelectedChange = item.customFunction;
                                } break;
                                case EventType.Cancel:
                                    scope.apxGridOptions.cancel = item.eFunction;
                                    break;
                                //case EventType.Save:
                                //    scope.apxGridOptions.save = item.eFunction;
                                //    break;
                                case EventType.Edit:
                                    scope.apxGridOptions.edit = (e) => {
                                        editablePopup(e);
                                        item.eFunction(e);
                                    };
                                    break;
                                case EventType.ExportToExcel:
                                    scope.apxGridOptions.excelExport = item.eFunction;
                                case EventType.Delete:
                                    scope.apxGridOptions.remove = item.eFunction;
                            }
                        });
                    }



                    var updateDataSource = (dataSource) => {

                        if (typeof scope.options.grid === "undefined") {
                            setTimeout(updateDataSource(dataSource), 20);
                            return;
                        }

                        scope.options.grid.setDataSource(dataSource);
                        if (!scope.options.isLookup) {
                            _.each($(element).find(".k-filter-row").find("input, .k-widget.k-dropdown.k-header"), (input) => {
                                $(input).on("focus", () => {
                                    if (!scope.options.isLookup && scope.options.grid !== undefined && scope.options.grid.element !== null) {
                                        scope.options.grid.clearSelection();
                                    }
                                });
                            });
                        }
                    };

                    //var rootWatcher = scope.$root.$watch("userData.currentCompany", function (newValue: any, oldValue: any) {
                    //    if (scope.options != null
                    //        && scope.options.dataSource != null) {

                    //        if (scope.options.dataSource.options != null
                    //            && scope.options.dataSource.options.transport != null
                    //            && scope.options.dataSource.options.transport.read != null) {
                    //            if (newValue != null) {
                    //                if (scope.options.dataSource.options.transport.read.headers != null)
                    //                    scope.options.dataSource.options.transport.read.headers["X-Apex-Company-Code"] = newValue.Code;
                    //                else
                    //                    scope.options.dataSource.options.transport.read.headers = { "X-Apex-Company-Code": newValue.Code };
                    //            } else if (scope.options.dataSource.options.transport.read.headers != null) {
                    //                delete scope.options.dataSource.options.transport.read.headers["X-Apex-Company-Code"];
                    //            }
                    //        }
                    //    }
                    //});

                    //scope.$on("destroy", function () {
                    //    if (rootWatcher)
                    //        rootWatcher();
                    //});

                    scope.$watch("options.dataSource", (dataSource) => {
                        if (typeof dataSource !== "undefined") {
                            updateDataSource(dataSource);
                        }
                    });
                    scope.$watch("options.dataSource.options.schema.model.id", (id) => {
                        if (id != undefined) {
                            datasourceId = id;
                        }
                    });
                    var translateProvider = Core.Utils.Helper.getServiceFromJs(Globals.translate);
                    var addCaption = translateProvider.instant('Add');
                    var editCaption = translateProvider.instant('Edit');
                    var deleteConfirmationMessage = translateProvider.instant('deleteConfirmation');
                    $timeout(() => {
                        $(element).find(".k-button.k-button-icontext.k-grid-edit").on("click", () => {
                            if ($(element).find(".k-button.k-button-icontext.k-grid-edit").hasClass("k-state-disabled")) {
                                return;
                            } else {
                                if (!scope.options.settings.editble || !scope.options.settings.editble.popupTemplateID)
                                    throw new Error("Please fill popupTempalteID field in options");

                                scope.options.grid.options.editable = {
                                    mode: "popup",
                                    window: { title: addCaption },
                                    template: "<div class='apx-kendo-popup'>" + $("#" + scope.options.settings.editble.popupTemplateID).html() + "</div>"
                                }
                                scope.options.grid.addRow();
                                scope.options.grid.options.editable = { confirmation: deleteConfirmationMessage }
                            }
                        });

                        $(element).find(".k-button.k-button-icontext.k-grid-edit-row").on("click", () => {
                            if (scope.options.grid.dataItem(scope.options.grid.select()) != null) {
                                if ($(element).find(".k-button.k-button-icontext.k-grid-row").hasClass("k-state-disabled")) {
                                    return;
                                } else {
                                    if (typeof scope.options.settings.editble.popupTemplateID === "undefined")
                                        throw new Error("Please fill popupTempalteID field in options");

                                    scope.options.grid.options.editable = {
                                        mode: "popup",
                                        window: { title: editCaption },
                                        template: "<div class='apx-kendo-popup'>" + $("#" + scope.options.settings.editble.popupTemplateID).html() + "</div>"
                                    }
                                    scope.options.grid.addRow();
                                    scope.options.grid.options.editable = { confirmation: deleteConfirmationMessage }
                                }
                            }
                        });

                        if (scope.options.grid != undefined) {
                            var grid: kendo.ui.Grid = scope.options.grid;
                            if (scope.options.grid != undefined && scope.options.grid.table != undefined && scope.options.grid.table != null) {
                                scope.options.grid.table.on("mousedown", (e) => {
                                    if (e.which === 3) {
                                        var el = grid.current();
                                        if (el != null) {
                                            var tr = el.closest("tr");
                                            if (!tr.hasClass("k-state-selected")) {
                                                grid.clearSelection();
                                                tr.addClass("k-state-selected");
                                                var ev: any = { sender: grid };
                                                scope.apxGridOptions.change(ev);
                                            }
                                        }
                                    }
                                });

                                scope.options.SelectAll = () => {
                                    var data = grid.dataSource.data();
                                    if(!data || data.length==0)
                                        return;
                                    for (var index = 0; index < data.length; index++) {
                                        scope.Select(data[index], true);
                                    }
                                }
                                scope.options.UnselectAll = () => {
                                    var data = grid.dataSource.data();
                                    if(!data || data.length==0)
                                        return;
                                    for (var index = 0; index < data.length; index++) {
                                        scope.Select(data[index], false);
                                    }
                                }
                                scope.Select = (rowData: any, value:boolean=null) => {
                                    // var data = grid.dataSource.data();
                                    Core.Utils.Helper.safeApply(scope, () => {
                                        if ((<any>rowData).isSelected != undefined) {
                                            if (scope.iSelectedChange) {
                                                var IsSelectedEvent: ISelectedEvent = {
                                                    AllowToChange: true,
                                                    newValue:value==null? !(<any>rowData).isSelected:value,
                                                    oldValue: (<any>rowData).isSelected,
                                                    object: rowData
                                                };
                                                scope.iSelectedChange(IsSelectedEvent);
                                                if (IsSelectedEvent.AllowToChange) (<any>rowData).isSelected = value==null? !(<any>rowData).isSelected:value;
                                            }
                                            else (<any>rowData).isSelected = value==null? !(<any>rowData).isSelected:value;
                                            // grid.dataSource.sync();
                                        }
                                    });
                                }

                                scope.options.grid.table.on("keyup ", (e) => {
                                    if (e.which == 32) {
                                        var row = grid.select();
                                        var rowData = grid.dataItem(row);
                                        scope.Select(rowData, null);
                                    }
                                });
                            }
                        }

                        if (scope.options.grid != undefined) {
                            var grid: kendo.ui.Grid = scope.options.grid;

                            var keyUp = 38;
                            var keyDown = 40;
                            if (scope.options.grid.table != undefined) {
                                scope.options.grid.table.keydown((e) => {
                                    // var cellIndx = -1;
                                    var editableCell = grid.select().find(".k-edit-cell");
                                    if (editableCell.length > 0) {
                                        return;
                                        //cellIndx = grid.select().children().index(editableCell);
                                    }
                                    switch (e.keyCode) {
                                        case keyDown:
                                            {
                                                var selectedItem = scope.options.grid.select().last();
                                                if (selectedItem.next().length == 0) {
                                                    grid.select(selectedItem);
                                                    e.preventDefault();
                                                    return;
                                                }
                                                grid.closeCell();
                                                grid.clearSelection();
                                                grid.select(selectedItem.next());
                                                grid.table.focus();
                                                //if (cellIndx > -1) {
                                                //    grid.select().children()[cellIndx].click();
                                                //}
                                                break;
                                            }
                                        case keyUp:
                                            {
                                                var selectedItem = scope.options.grid.select().first();
                                                if (selectedItem.prev().length == 0) {
                                                    grid.select(selectedItem);
                                                    e.preventDefault();
                                                    return;
                                                }

                                                grid.closeCell();
                                                grid.clearSelection();
                                                grid.select(selectedItem.prev());
                                                grid.table.focus();
                                                //if (cellIndx > -1) {
                                                //    grid.select().children()[cellIndx].click();
                                                //}
                                                break;
                                            }
                                    }
                                });
                            }

                        };

                        if (scope.options.initCallback !== undefined) {
                            scope.options.initCallback();
                        }
                    });

                    scope.options.gridView = {
                        grid: scope.options.grid,
                        refresh: () => {
                            var selectedItem = scope.options.gridView.selectedItem();


                            scope.options.grid.dataSource.read();
                            scope.options.grid.dataSource.page(1);
                        }, setFilter: (filterParams) => {
                            if (typeof scope.options.grid !== "undefined") {
                                var filterRow = '';
                                var i = 0;

                                filterParams.forEach(filter => {
                                    i++;
                                    if (filter.filterValue != null && filter.filterValue != undefined && (filter.filterValue.length > 0 || filter.filterValue.toString().length > 0)) {
                                        filterRow += filter.filterName + filter.filterOperator + '=' + filter.filterValue;
                                        if (i < filterParams.length) {
                                            filterRow += '&';
                                        }
                                    }
                                });
                                var url = '';

                                if (scope.options.grid.dataSource.options.transport != undefined) {
                                    let read = <kendo.data.DataSourceTransportRead>scope.options.grid.dataSource.options.transport.read;
                                    let url = read.url.split('?')[0];
                                    read.url = filterRow !== '' ? url + '?' + filterRow : url;
                                }



                                //scope.options.gridView.refresh();
                            }
                        },
                        refreshDatasource: () => {

                        }, selectedItem: () => {
                            try {
                                var selected = scope.options.grid.select();
                                return scope.options.grid.dataItem(selected);
                            } catch (e) {
                                console.warn("getSelectedDataItem is failed, ", scope.options.grid, e);
                            }
                            return null;
                        },
                        showLoading: () => {
                            scope.loadingVisibled = true;
                        },
                        hideLoading: () => {
                            scope.loadingVisibled = false;
                        }


                    };

                    scope.$on('$destroy', () => {
                        kendo.destroy($(element));
                    });
                }
            }
        }

        public static factory() {
            var directive = ($compile) => {
                return new ApxGrid($compile);
            };
            directive['$inject'] = ['$compile'];
            return directive;
        }
    }

    export class ApxExpandKGrid implements angular.IDirective {

        restrict = 'A';
        constructor(private $window) {
        }
        link = (scope, element, attrs) => {
            var outSet = Number(attrs.apxExpandKGrid);

            function resizeGrid() {
                var gridElement = $(element);
                var dataElement = gridElement.find('.k-grid-content');
                var nonDataElements = gridElement.children().not('.k-grid-content');
                if (nonDataElements.length === 0) {
                    setTimeout(() => { resizeGrid() }, 50);
                    return;
                }
                var currentGridHeight = $("#pageBody").innerHeight() - (25 + outSet);
                var nonDataElementsHeight = outSet;
                nonDataElements.each(function (itm) {
                    var currentNonDataElementsHeight = $(this).outerHeight();
                    if (currentNonDataElementsHeight + outSet < currentGridHeight) {
                        nonDataElementsHeight += currentNonDataElementsHeight;
                    }
                });

                if (nonDataElementsHeight != outSet) {
                    var val = currentGridHeight - nonDataElementsHeight;
                    dataElement.height(val);
                }
            }

            $(this.$window).resize(() => {
                resizeGrid();
            });
            resizeGrid();
        }

        public static factory() {
            var directive = ($window) => {
                return new ApxExpandKGrid($window);
            };
            directive['$inject'] = ['$window'];
            return directive;
        }
    }
}