/// <reference path='./../_reference.ts' />
module Apex.Components.Directives {
    "use strict";
    export class ApxModal implements angular.IDirective {
        restrict = 'E';
        replace = true;
        templateUrl = "apx.modal.html";
        public static factory() {
            var directive = () => {
                return new ApxModal();
            };
            return directive;
        }
    }
}