/// <reference path='./../_reference.ts' />
module Apex.Components.Directives {
    "use strict";
    export class ApxLeaflet implements angular.IDirective {
        restrict = "E";
        // template = "<div id='apx-leaf-let-{{$id}}'><leaflet id='map' apx-leaf-let-map-expand width='100%' style='color:black;'></leaflet></div>";
        templateUrl = "apx.leaflet.html";
        scope = {
            mapLayers: '=?',
            // mapLayersAdd:'=',
            mapIfCenter: '=?',
            mapDefaults: '=?',
            mapMarkers: '=?',
            mapControll: '=?',
            mapClick: '=?'       

        };
        controller = Apex.Components.Controllers.ApxLeafletController.getName();
        link = (scope, element, attrs, controller) => {
           

            scope.$on("leafletDirectiveMap.map.click", (e, args) => {
                if (attrs.$attr.addMark != undefined){                    
                    controller.addMarkers(args.leafletEvent.latlng, "")
                    scope.mapMarkers=controller.$scope.maps.markers;
                }
                else
                    if (attrs.$attr.pointMark != undefined){
                        controller.addMarkers(args.leafletEvent.latlng, "one")
                        scope.mapMarkers=controller.$scope.maps.markers;
                    }
                if (scope.mapClick != undefined) {
                    scope.mapClick(e, args);
                }
            });

           
            scope.$watch('mapLayers', (value) => {
                if (value != undefined)
                    controller.$scope.maps.layers = value;
            });
            scope.$watch('mapIfCenter', (value) => {
                if (value != undefined)
                    controller.$scope.maps.center = value;
            });

            scope.$watch('mapDefaults', (value) => {
                if (value != undefined)
                    controller.$scope.maps.defaults = value;

            });
            scope.$watch('mapMarkers', (value) => {
                if (value != undefined) {
                    controller.$scope.maps.markers = value;
                }
            });
            scope.$watch('mapControll', (value) => {
                value = controller.$scope.maps.mapControl;
            });

        }

        public static factory() {
            var directive = () => {
                return new ApxLeaflet();
            };
            return directive;
        }
    }
}