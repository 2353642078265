/// <reference path='./../_reference.ts' />
module Apex.Components.Directives {
    "use strict";

    export class ApxRadio implements angular.IDirective {
        restrict = 'E';
        replace = true;
        scope = {
            ngModel: '=',
            apxArray: '='
        };

        link(scope) {
            
            if (typeof scope.apxArray.val !== "undefined") {
                scope.optionsArray = scope.apxArray;
            } else {
                scope.optionsArray = [];
                scope.apxArray.forEach((item) => {
                    scope.optionsArray.push({ val: item, title: item });
                });
            }

            scope.options = {
                radio: scope.ngModel
            }

            scope.$watch('ngModel', (val) => {
                if (val != undefined) scope.options.radio = val;
            });

            scope.$watch('options.radio',() => {
                scope.ngModel = scope.options.radio;
            });

        }

        templateUrl = 'apx.radio.html';

        public static factory() {
            var directive = () => {
                var apxRadio = new ApxRadio();
                return apxRadio;
            };
            return directive;
        }
    }

} 