/// <reference path='./../_reference.ts' />
module Apex.Components.Directives {
    "use strict";

    export class ApxRow implements angular.IDirective {
        restrict = "AE";
        transclude = true;
        replace = true;
        template = '<div class="form-group pull-in clearfix row" ng-transclude></div>';
        link(scope: angular.IScope, instanceElement: angular.IAugmentedJQuery, instanceAttributes: angular.IAttributes, controller, transclude: angular.ITranscludeFunction): void { }

        public static factory() {
            var directive = () => {
                return new ApxRow();
            };
            return directive;
        }

    }
} 