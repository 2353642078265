/// <reference path='./../_reference.ts' />
module Apex.Components.Directives {
    "use strict";
    export class ApxDropdown implements angular.IDirective {
        restrict = 'AE';
        require = ['ngModel'];
        replace = true;
        scope = {
            ngModel: '=',
            apxDatasource: '=',
            apxRefreshDs: '&',
            kNgModel: '=',
            ngDisabled: '=',
            eventOnChange: '=?',
            /** თუ მონაცემები ცარიელია მოქაბს მონაცემები ფილტრის მიხედვით თუ არა მაშინ მინიჭებული მნიშბნელობის მიხედვით 
             * ხდებე ობიექტის ამორჩევა (select).
             * გამოყენების სინტაქსი: 
             *  კონტროლერში=>
             * getlazyData = (id: string)=> {
             *      if (id)
             *          return this.service.getTestDataById(id);
             *      else return this.service.getTestData();
             * }
             * Html ნატილში:
             *  <apx-dropdown   ng-model="Model.Id" 
             *                  apx-datasource="Model.dataSource"
             *                  apx-value-id="Id"
             *                  apx-value-text="Name"
             *                  apx-get-data="getTestData(Model.Id)">
             * 
             */
            apxGetData: '&'
        };
        templateUrl = 'apx.dropdown.html';

        constructor(private $compile) { }

        compile = () => {
            var compile = this.$compile;
            return {
                pre(scope, element, iAttrs) {
                    if (typeof iAttrs.apxValueId === "undefined" || typeof iAttrs.apxValueText === "undefined") {
                        throw Error('apx-value-id and apx-value-text is requered');
                    }
                    scope.dropdownOptions = <kendo.ui.DropDownListOptions>{
                        dataValueField: iAttrs.apxValueId,
                        filter: 'contains',
                        dataTextField: iAttrs.apxValueText
                    };


                    var attributes = $(element).prop("attributes");

                    var input = $(element).find("select");
                    let preValue:any;
                    if (scope.eventOnChange != undefined && input != undefined) {
                        input.on('change', () => {
                            scope.eventOnChange() 
                        });
                    }
                    $.each(attributes, function () {
                        var name = this.name;
                        var val = this.value;
                        if (name !== "class" && name !== 'apx-col-span' && name !== 'apx-col-offse t' && name !== 'apx-dont-mutch') {
                            input.attr(name, val);
                        }
                    });
                    var dontRemovedAttr = ['apx-dont-mutch'];
                    Core.Utils.Helper.removeAttributes(element, dontRemovedAttr);
                    //compile(element)(scope);
                },
                post(scope, element, iAttrs) {
                    scope.dropdownOptions.select = (e) => {
                        Apex.Core.Utils.Helper.safeApply(scope, () => {
                            scope.ngModel = e.dataItem[iAttrs.apxValueId];
                        });
                    }
                    var isLoaded = false;
                    if (!!scope.apxDatasource) {
                        if (scope.apxDatasource instanceof kendo.data.DataSource) {
                            scope.dropdownOptions.dataSource = scope.apxDatasource;
                            if (scope.ngModel !== undefined) {
                                scope.dropdownOptions.dataSource.filter({ field: scope.dropdownOptions.dataValueField, operator: "startswith", value: scope.ngModel });
                            }
                        } else {

                            scope.dropdownOptions.dataSource = Core.Kendo.GridView.dataToDataSource(scope.apxDatasource);
                        }

                    } else {

                        scope.dropdownOptions.dataSource = new kendo.data.DataSource(null);
                    }

                    function refreshDropdown() {
                        var id = '#apxDropDown-' + scope.$id;
                        var dropDown = $(id).data("kendoDropDownList");

                        if (typeof dropDown === "undefined") {
                            setTimeout(() => {
                                refreshDropdown();
                                return;
                            }, 300);
                        }

                        if (typeof dropDown !== "undefined" && dropDown !== null) {
                            var ngModel = scope.ngModel;
                            if (scope.apxDatasource instanceof kendo.data.DataSource) {
                                scope.apxDatasource.read();
                            } else {
                                dropDown.dataSource.data(scope.apxDatasource);
                                dropDown.refresh();
                            }
                            if (ngModel !== undefined)
                                scope.ngModel = ngModel;
                        }
                    }

                    scope.$watch('apxDatasource', (val) => {
                        if (typeof val === "undefined" || val === null) return;
                        refreshDropdown();
                    });


                    scope.$watch('ngModel', (val) => {
                        if (val && iAttrs.$attr.apxGetData && scope.apxGetData
                            && !scope.apxIsDropDownLoaded) {
                            scope.apxGetData({ data: val }).then((response) => {
                                scope.apxDatasource = response.Result;
                                scope.apxIsDropDownLoading = false;
                                scope.apxIsDropDownLoaded = true;
                            });
                        }
                    });

                    scope.onOpen = () => {
                        scope.apxIsDropDownLoaded = false;
                        scope.apxIsDropDownLoading = false;

                        if (!isLoaded) {
                            if (iAttrs.$attr.apxGetData && scope.apxGetData
                                && !scope.apxIsDropDownLoaded
                                && !scope.apxIsDropDownLoading) {
                                scope.apxIsDropDownLoading = true;

                                try {
                                    // kendo.ui.progress($('#apxDropDown-' + scope.$id), true);
                                    scope.apxGetData().then((response) => {
                                        if (response.Result instanceof Array == false)
                                            scope.apxDatasource = [].concat(response.Result);
                                        else
                                            scope.apxDatasource = response.Result;
                                        scope.apxIsDropDownLoading = false;
                                        scope.apxIsDropDownLoaded = true;
                                        // kendo.ui.progress($('#apxDropDown-' + scope.$id), false);
                                    });
                                } catch (ex) {
                                    // kendo.ui.progress($('#apxDropDown-' + scope.$id), false);
                                    scope.apxIsDropDownLoading = false;
                                }
                            }
                        }
                        if (isLoaded) return;
                        if (typeof scope.apxDatasource === "undefined" || scope.apxDatasource === null || scope.apxDatasource.length < 2) {
                            scope.$apply(scope.apxRefreshDs);
                        }
                        isLoaded = true;
                    };
                    scope.$on('$destroy', () => { kendo.destroy($("#apxDropDown-" + scope.$id + "-list")); });
                }
            }
        }
        public static factory() {
            var directive = ($compile) => {
                return new ApxDropdown($compile);
            };
            directive['$inject'] = ['$compile'];
            return directive;
        }
    }
}