/// <reference path='./../_reference.ts' />
module Apex.Components.Directives {
    "use strict";

    export class ApxPageContent implements angular.IDirective {
        restrict = "EA";
        transclude = true;
        replace = true;
        templateUrl = 'apx.page.content.html';
        link(scope, element) {
            scope.apxLoading = true;
            setTimeout(() => {
                Core.Utils.Helper.safeApply(scope, () => { scope.apxLoading = false; });
            }, 250);

            setTimeout(() => {
                var hg = $("#page-head").height();
                var newHeight = hg + 20;
                $("#pageBody").css('height', "calc(100% - " + newHeight + "px)");
                $(window).resize();
            }, 50);

            $("#page-head").on("heightChange", e => {
                setTimeout(() => {

                    var hg = $("#page-head").height();
                    var newHeight = hg + 14;
                    $("#pageBody").css('height', "calc(100% - " + newHeight + "px)");
                    $(window).resize();
                }, 50);
            });
        }
        public static factory() {
            var directive = () => {
                return new ApxPageContent();
            };
            return directive;
        }
    }
} 