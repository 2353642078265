/// <reference path='./../_reference.ts' />
module Apex.Components.Directives {
    "use strict";

    export class ApxDatebetween implements angular.IDirective {
        restrict = 'AE';
        replace = false;
        scope = {
            dateBetween: '=ngModel',
            ngDisabled: '='
        };
        require = ['ngModel'];
        templateUrl = 'apx.date.between.html';
        controller = Components.Controllers.ApxDatebetween.getName();
        link = (scope, element) => {

        }

        public static factory() {
            var directive = () => {
                var dateBeetwen = new ApxDatebetween();
                return dateBeetwen;
            };
            return directive;
        }
    }
} 