/// <reference path='./../_reference.ts' />
module Apex.Components.Directives {
    "use strict";

    export class ApxEnter implements angular.IDirective {
        restrict = "A";
        link = (scope: any, element: angular.IAugmentedJQuery, attrs: any) => {
            var focustTo = (item) => {
                setTimeout(() => {
                    var itm = $(item).is("select");
                    if (itm) {
                        $(item).parent().first().focus();
                    } else if ($(item).find(".apx-lookup").length > 1) {
                        $(item).find("select").parent().first().focus();
                    } else if ($(item).is("input")) {
                        $(item).focus();
                        $(item).select();
                    } else {
                        $(item).find("input").first().focus();
                        $(item).find("input").first().select();
                    }
                }, 50);
            }
            element.on("keypress", (e) => {
                if (e.keyCode === 13) {
                    var current = attrs.apxEnter;
                    var changed = false;
                    var navigationObjects = $("[apx-enter-id='" + attrs.apxEnterId + "']").toArray();
                    navigationObjects.forEach((item) => {
                        if (Number(current) + 1 === Number($(item).attr("apx-enter"))) {
                            focustTo(item);
                            changed = true;

                        }
                    });
                    if (!changed)
                        focustTo($(navigationObjects[0]));
                }
            });
            setTimeout(() => {
                if (Number(attrs.apxEnter) === 1) {
                    focustTo(element);
                }
            }, 500);
        };

        constructor(private $timeout) {

        }

        public static factory() {

            var directive = ($timeout) => {
                return new ApxEnter($timeout);
            };
            directive['$inject'] = ['$timeout'];
            return directive;
        }
    }
}  