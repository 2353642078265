/// <reference path='./../_reference.ts' />
declare var JsBarcode: any;
module Apex.Components.Directives {
    "use strict";
    export class ApxBarcode implements angular.IDirective {
        restrict = "E";
        replace = true;
        template = '<svg id="ean-13"></svg>';
        scope = {
            ngModel: '='
        };
        link = (scope: any, element: angular.IAugmentedJQuery, attrs: angular.IAttributes, ctrl, transclude) => {
            scope.$watch("ngModel", (val: any) => {
                try {
                    JsBarcode("#ean-13", val, { background: "rgba(0, 0, 0, 0)", width: 2, height: 45, displayValue: false });
                } catch (e) {
                    
                }
            });
        };
        public static factory() {

            var directive = () => {
                return new ApxBarcode();
            };
            return directive;
        }
    }
}  