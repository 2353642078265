/// <reference path='./../_reference.ts' />
module Apex.Components.Directives {
    "use strict";

    export interface ITreeScope extends angular.IScope {
        options: Components.IApxTreeList;
        apxTreeListOptions: kendo.ui.TreeListOptions;
        popupModel?: any;
        loadingVisibled: boolean;
        value: string;
        isFiltereble?: boolean;

    }
    export class ApxTreeList implements angular.IDirective {
        restrict = 'E';
        templateUrl = 'apx.treelist.html';
        replace = true;
        scope = {
            options: "="
        }
        constructor(private $compile) { }
        compile = () => {
            var compile = this.$compile;
            return {
                pre(scope, element, attr) {
                    if (typeof attr.options === "undefined") {
                        throw Error("options is requered!");
                    }
                    var attributes = $(element).prop("attributes");
                    var treelist = $(element).find("div");
                    var ignoreAttributes = ['class', 'apx-col-span', 'apx-col-offset'];

                    _.each(attributes, (item: { name; value; }) => {
                        if (!_.contains(ignoreAttributes, item.name)) {
                            treelist.attr(item.name, item.value);
                        }
                    });
                    Core.Utils.Helper.removeAttributes(element, []);
                    compile(element)(scope);
                },
                post(scope: ITreeScope, element) {
                    $(element).prepend('<apx-loading visibled="loadingVisibled" style="opacity: 0.7;"></apx-loading>');
                    scope.loadingVisibled = false;
                    compile($(element).find("apx-loading"))(scope);

                    // სათაურებს გადათარგმნის
                    _.each(scope.options.options.columns, (item) => {
                        item.title = "{{'" + item.titleTranslated + "' | translate}}";
                        item.headerTemplate = '<div uib-tooltip="{{\'' + item.titleTranslated + '\' | translate}}" style="text-overflow: ellipsis;display: inline;" tooltip-append-to-body="true" tooltip-animation="true" translate="' + item.titleTranslated + '"> </div>';
                    });
                    // scope.options.Id="apx-tree-list-"+scope.$id.toString();
                    // **********************************************
                    // scope.isFiltereble = scope.options.options.isfilterable;
                    scope.apxTreeListOptions = scope.options.options;
                    // ჩანაწერზე (row) ფოკუსის აღდგენა 
                    var changeFunction = scope.options.options.change;
                    var dataBoundFunction = scope.options.options.dataBound;
                    var selectedItem;
                    scope.apxTreeListOptions.change = (e) => {
                        selectedItem = null;
                        if ((<JQuery>e.sender.select()).data() !== undefined)
                            selectedItem = (<JQuery>e.sender.select()).data().$scope.dataItem;
                        if (changeFunction)
                            changeFunction(e);
                    }

                    scope.apxTreeListOptions.dataBound = (e) => {
                        var dataSource = e.sender.dataSource.data();
                        if (!selectedItem)
                            return;
                        for (var i = 0; i < dataSource.length; i++) {
                            if (dataSource[i][e.sender.dataSource.options.schema.model.id] == selectedItem[e.sender.dataSource.options.schema.model.id]) {
                                e.sender.select($("[data-uid='" + dataSource[i].uid + "']"));
                                break;
                            }
                        }
                        if (dataBoundFunction)
                            dataBoundFunction(e);
                    }
                    // ***********************************************

                    scope.options = {
                        Id:"apx-tree-list-"+scope.$id.toString(),
                        showLoading: () => {
                            scope.loadingVisibled = true;
                        },
                        hideLoading: () => {
                            scope.loadingVisibled = false;
                        }
                    }
                    setTimeout(() => {
                        if (scope.options.treeList != undefined) {
                            var table = scope.options.treeList.element.find("table").on("mousedown", "tr[role='row']", (e) => {
                                if (e.which === 3) {                                    
                                    // scope.options.treeList.clearSelection();
                                    scope.options.treeList.element.find(".k-state-selected").removeClass("k-state-selected");
                                    if($(e.currentTarget).parent("thead").length>0)
                                        return;
                                    
                                    $(e.currentTarget).addClass("k-state-selected");
                                    var ev: any = { sender: scope.options.treeList };
                                    scope.apxTreeListOptions.change(ev);
                                }
                            });

                        }
                    }, 300);

                    // scope.$watch("options.treeList", (val: kendo.ui.TreeList, olVal: any, sc: any) => {
                    //     if(val){
                    //         var table=val.element.find("table").on("mousedown","tr[role='row']", (e)=> {
                    //             if (e.which === 3) {
                    //                 val.clearSelection();
                    //                 $(e.currentTarget).addClass("k-state-selected");
                    //                 var ev:any={sender:val};
                    //                 this.scope.options.treeList.options.change(ev);
                    //             }
                    //         });
                    //     }
                    // });

                    // var filterArea = `
                    // <tr role="row" id="fileterArea">
                    // </tr>
                    // `;
                    // var filterElement = `                     
                    //     <th>
                    //         <span  class="k-filtercell" style="padding-left: 4px !important;padding-top: 5px !important; padding-bottom: 5px !important;">
                    //             <span class="k-operator-hidden">
                    //                 <span tabindex="-1"  class="k-widget k-autocomplete k-header k-state-default" >
                    //                     <input  type="text" class="k-input" style="width: 100%; ">
                    //                     <span class="k-icon k-loading" style="display:none"></span>
                    //                 </span>
                    //                 <button type="button" class="k-button k-button-icon" title="Clear" data-bind="visible:operatorVisible" style="display: none;">
                    //                 <span class="k-icon k-i-close"></span>
                    //                 </button>
                    //             </span>
                    //         </span>
                    //     </th>                   
                    //   `;



                    // scope.$watch("options.treeList", (val: any, olVal: any, sc: any) => {
                    //     // th ის დამატება
                    //     $(val.thead).append(filterArea);
                    //     // ფილტრის დმატება ჰედერში
                    //     _.each((<kendo.ui.TreeList>val).options.columns, (item) => {
                    //         if (!item.hidden) {
                    //             var f_element_with_attr = $(filterElement);
                    //             f_element_with_attr.find('.k-filtercell').attr('data-field', item.field)
                    //             f_element_with_attr.find('.k-input').attr('id', item.field);
                    //             $(val.thead).find("#fileterArea").append(f_element_with_attr)
                    //         }
                    //     });

                    //     // TODO: fix filters two or more columns! ! ! 

                    //     // keyDown ევენთის მიბაინდება
                    //     $(val.thead).find(".k-input").bind("keydown keypress", (event) => {
                    //         var keyCode = event.which || event.keyCode;
                    //         // If enter key is pressed
                    //         if (keyCode === 13) {
                    //             scope.$apply(function () {
                    //                 var span = $(val.thead).find('[data-field="' + event.target.id + '"]');

                    //                 var value = $(event.target).val();

                    //                 if (value !== "undefine" && value !== "" && value !== null) {
                    //                     span.find("button").removeAttr("style");

                    //                     var filter: kendo.data.DataSourceFilters = scope.options.treeList.dataSource.filter();
                    //                     if (!!filter) {
                    //                         var filterField = _.find(filter.filters, { field: event.target.id });
                    //                         if (!!filterField) {
                    //                             filter.filters.splice(filter.filters.indexOf(filterField));
                    //                         }

                    //                         filter.filters.push({
                    //                             field: event.target.id,
                    //                             operator: "contains",
                    //                             value: $(event.target).val()
                    //                         });
                    //                     }
                    //                     else filter = [{
                    //                         field: event.target.id,
                    //                         operator: "contains",
                    //                         value: $(event.target).val()
                    //                     }];
                    //                     scope.options.treeList.dataSource.filter(filter);
                    //                 } else {
                    //                     scope.options.treeList.dataSource.filter({})
                    //                     span.find("button").attr("style", "display: none;")
                    //                 }
                    //             });
                    //             event.preventDefault();
                    //         }
                    //     })

                    //     $(val.thead).find("button").bind("click", (event) => {

                    //         $(event.currentTarget).attr("style", "display: none;");
                    //         $(event.currentTarget).parent().find("input").val("");
                    //         var inp_id = $(event.currentTarget).parent().find("input").attr("id");
                    //         var filter: kendo.data.DataSourceFilters = scope.options.treeList.dataSource.filter();

                    //         filter.filters = _.without(filter.filters, _.findWhere(filter.filters, {
                    //             field: inp_id
                    //         }));

                    //         scope.options.treeList.dataSource.filter(!!filter ? filter : {});
                    //         event.preventDefault();
                    //     });

                    //     sc.options.treeList.element.on("mousedown", "tr[role='row']", (evn) => {
                    //         if (evn.which == 3) {
                    //             if (sc != undefined) {
                    //                 sc.options.treeList.element.find("tr").removeClass("k-state-selected");
                    //                 var tbody = $(evn.target).closest("tbody");
                    //                 if (tbody.length > 0) {
                    //                     var table_row = $(evn.target).closest("tr");
                    //                     if (table_row != undefined)
                    //                         table_row.closest("tr").addClass("k-state-selected");
                    //                     var ev: any = { sender: scope.options.treeList };
                    //                     sc.options.treeList.options.change(ev);
                    //                 }
                    //             }
                    //         }
                    //     });
                    //     // ****************************                        
                    // });


                }
            }
        }
        public static factory() {
            var directive = ($compile) => {
                return new ApxTreeList($compile);
            };
            directive['$inject'] = ['$compile'];
            return directive;
        }
    }
    export class ApxExpandKTreeList implements angular.IDirective {

        restrict = 'A';
        constructor(private $window) {
        }
        link = (scope, element, attrs) => {
            var outSet = Number(attrs.apxExpandKTreeList);
            var parent: any;
            function resizeTreeList() {
                var treeListElement = $(element);
                var dataElement = treeListElement.find('.k-grid-content');
                var nonDataElements = treeListElement.children().not('.k-grid-content');
                if (nonDataElements.length === 0) {
                    setTimeout(() => { resizeTreeList() }, 50);
                    return;
                }
                var currentTreeListHeight = $("#pageBody").innerHeight() - (25 + outSet);
                var nonDataElementsHeight = outSet;
                nonDataElements.each(function (itm) {
                    var currentNonDataElementsHeight = $(this).outerHeight();
                    if (currentNonDataElementsHeight + outSet < currentTreeListHeight) {
                        nonDataElementsHeight += currentNonDataElementsHeight;
                    }
                });

                if (nonDataElementsHeight != outSet) {
                    var val: number;
                    parent = treeListElement.parent();
                    if (parent !== undefined && parent !== null) {
                        val = parent.height();
                        parent = parent.parent();
                        if (parent !== undefined && parent !== null) {
                            val = parent.height();
                        }
                    }
                    dataElement.height(val);
                    treeListElement.height(val);
                    // treeListElement.height(val);
                }
            }
            $(this.$window).resize(() => {
                resizeTreeList();
            });
            resizeTreeList();
        }

        public static factory() {
            var directive = ($window) => {
                return new ApxExpandKTreeList($window);
            };
            directive['$inject'] = ['$window'];
            return directive;
        }
    }
}