///<reference path="./../_reference.ts"/>

module Apex.Components {
    export interface IApxNumber {
        decimals?: number;
        min?: number;
        max?: number;
        allowMinus?:boolean;
        numberType?: NumberType;
    }
    export enum NumberType {
        Integer,
        Decimal
    }
}
