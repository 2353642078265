/// <reference path='./../_reference.ts' />
module Apex.Components.Directives {
    "use strict";
    export class ApxSetParentSize implements angular.IDirective {
        restrict = 'A';
        constructor(private $window) {
        }

        link = (scope, element, attrs) => {
            var canLog = false;
            var parentElementFilter = String(attrs.apxSetParentSize);
            var contentFilter = String(attrs.apxContent);
            var elemOutSizeFilter = String(attrs.apxElemOutSize);
            canLog ? console.log("contentFilter=", contentFilter) : null;
            canLog ? console.log("parentElementFilter", parentElementFilter) : null;
            canLog ? console.log("elemOutSizeFilter", elemOutSizeFilter) : null;



            var parentElement: JQuery;
            var contentElement: JQuery;
            var elemOuts: JQuery[];

            if (parentElementFilter == undefined) {
                parentElement = $(element).parent();
            } else {
                var splitedData = parentElementFilter.split(" ");
                canLog ? console.log("splited data=", splitedData) : null;
                for (var i = 0; i < splitedData.length; i++) {
                    if (parentElement == undefined)
                        parentElement = $(splitedData[i]);
                    else
                        parentElement = parentElement.find(splitedData[i]);
                }

            }



            var parentElementLastHeight = parentElement.css('height');
            function checkForChanges() {
                if (parentElement.css('height') != parentElementLastHeight) {
                    parentElementLastHeight = parentElement.css('height');
                    canLog ? console.log("parentElementLastHeight=", parentElementLastHeight) : null;
                    resizeElement(parentElementLastHeight);
                }
                setTimeout(checkForChanges, 200);
            }


            function resizeElement(parentElementHeight) {
                canLog ? console.log("=============================================") : null;
                var _Element: JQuery;
                if (contentFilter != undefined && contentElement == undefined) {
                    contentElement = $(element).find(contentFilter).first();
                    canLog ? console.log("სახელი: " + contentFilter + "; მოძებნილი content ელემენტი არის:", contentElement) : null;

                }


                if (contentElement != undefined && contentElement.length != 0)
                    _Element = contentElement;
                else _Element = $(element);
                canLog ? console.log("_Element არის:", _Element) : null;


                if (elemOutSizeFilter != undefined && elemOuts == undefined) {
                    var elemOutSizeFilters = String(elemOutSizeFilter).split(';')
                    elemOuts = [];
                    for (var index = 0; index < elemOutSizeFilters.length; index++) {
                        elemOuts.push($(element).find(elemOutSizeFilters[index]).first());
                    }
                    canLog ? console.log("სახელი: " + elemOutSizeFilter + "; მოძებნილი OutElment: ", elemOuts) : null;
                }

                if (parentElementHeight == undefined || parentElementHeight == 0)
                    return;

                canLog ? console.log("სახელი არის:" + parentElementFilter + "; მშობელი კონტროლის სიმაღლე =", parentElementHeight) : null;

                var outSize: number = 0;
                for (var index = 0; index < elemOuts.length; index++) {
                    var height: string = elemOuts[index].css('height');

                    if (height == undefined) {
                        outSize+= elemOuts[index].innerHeight();
                    }
                    else{
                        outSize += Number(height.replace("px", ""));
                    }
                }
                canLog ? console.log("OutElment ელემენტის სიმაღლე =", outSize) : null;


                canLog ? console.log("გამოკლებამდე ძირიტადი ზომა =", parentElementHeight) : null;
                if (outSize != undefined && outSize) {
                    canLog ? console.log("გაპარსული სტრინგი=", (<String>parentElementHeight).split("px", 1).toString()) : null;
                    parentElementHeight = Number((<String>parentElementHeight).split("px", 1).toString()) - outSize + "px";
                }
                canLog ? console.log("გამოკლების შემდეგ ძირიტადი ზომა =", parentElementHeight) : null;
                _Element.height(parentElementHeight);
                canLog ? console.log("***********************************************") : null;


            }



            $(this.$window).resize(() => {
                checkForChanges();
            });
            checkForChanges();

        }

        public static factory() {
            var directive = ($window) => {
                return new ApxSetParentSize($window);
            };
            directive['$inject'] = ['$window'];
            return directive;
        }
    }
}