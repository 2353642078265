/// <reference path='./../_reference.ts' />
module Apex.Components.Controllers {
    import Helper = Apex.Core.Utils.Helper;
    export interface ILookupScope extends angular.IScope {
        options: Components.IApxLookup;
        lookupOptions: kendo.ui.DropDownListOptions;
        gridOptions: Components.IApxGrid;
        gridMouseEnter?: boolean;
        tableFocuseEvent?: boolean;
        onChange(data: any): void;
        ngModel: any;
        lookupObject: kendo.ui.DropDownList;
        model: any;
        apxPlaceholder: string;
        eventOnChange: Function;
        apxRequired: boolean;
        element: any;
    }

    export class Lookup extends Core.Controller {
        public static $inject = [
            Globals.scope, '$element', '$timeout'
        ];

        constructor(private $scope: ILookupScope, $element, private $timeout) {
            super(arguments, Lookup.$inject);

            var calculatedHeight = $(document).height() / 2 - 20;

            // var focused;
            $scope.gridMouseEnter = false;
            
            var rowSelected = false;
            var popupIsOpened=false;
            var rootScope = Apex.Core.Utils.Helper.getServiceFromJs(Globals.rootScope);
            var translate = Helper.getServiceFromJs(Globals.translate);

            var onChange = (e: kendo.ui.GridEvent) => {
                Apex.Core.Utils.Helper.safeApply($scope, () => {
                    var grid = e.sender;
                    $scope.ngModel = grid.dataItem(grid.select());
                    $scope.model = $scope.ngModel;
                    rowSelected = true;
                });

            }

            $scope.gridOptions = {
                isLookup: true,
                columns: $scope.options.columns,
                settings: { height: calculatedHeight - 4, virtualScroll: true },
                gridEvents: [{ eType: Components.EventType.Change, eFunction: onChange }]
            }

            $scope.$watch("options.dataSource", (val: any) => {
                $scope.gridOptions.dataSource = val;
                $scope.gridOptions.gridView.hideLoading();
            });
            // $scope.$watch("lookupObject",(val:any,oldVal,scop)=>{
            //     console.log(val);
            //     val.list.width("auto");
            // });

            $scope.$watch("ngModel", (val) => {

                if (typeof val === "undefined" || val == null) {
                    if ($scope.apxPlaceholder) {
                        rootScope.$on('$translateChangeSuccess', () => {
                            $scope.lookupObject.span[0].textContent = translate.instant($scope.apxPlaceholder);
                        });
                        $scope.lookupObject.span[0].textContent = translate.instant($scope.apxPlaceholder);
                        
                    } else {
                        $scope.lookupObject.span[0].textContent = "";
                    }
                }
                else {
                    $scope.lookupObject.span[0].textContent = val[$scope.options.valueText];
                    $scope.options.value = val[$scope.options.valueID];
                }

            });

            var lookupObject = "";
            if (!!$scope.ngModel)
                lookupObject = $scope.ngModel[$scope.options.valueText];

            var lookupVirtualDS = new kendo.data.DataSource({
                data: [
                    {
                        lookupObject: lookupObject
                    }
                ]
            });


            $scope.lookupOptions = {
                dataTextField: 'lookupObject',
                dataValueField: 'lookupObject',
                dataSource: lookupVirtualDS,
                close: (e: any) => {
                  
                    if ($scope.gridMouseEnter && !rowSelected) {
                        if ($scope.apxPlaceholder && e.sender.selectedIndex == 0 && !$scope.apxRequired) {
                            $scope.gridOptions.grid.clearSelection();
                            e.sender.select(1);
                            $scope.lookupObject.span[0].textContent = translate.instant($scope.apxPlaceholder);
                            return;
                        } 
                        else {
                            if ($scope.gridMouseEnter || $scope.tableFocuseEvent) {
                                 e.preventDefault();
                                $scope.gridMouseEnter = false;
                                $scope.tableFocuseEvent=false;
                                return;
                            }
                        }
                    }
                    else
                        rowSelected = false; 

                    popupIsOpened=false;                    
                    var models = $scope.options.valueText.split(".");

                    var lastModel = $scope.ngModel;

                    _.each(models, (item: string) => {
                        if (lastModel != undefined)
                            lastModel = lastModel[item];
                    });

                    if ($scope.gridOptions.grid.dataSource.filter() != undefined && $scope.gridOptions.grid.dataSource.filter().filters.length > 0) {
                       $scope.gridOptions.grid.dataSource.filter([]);
                    }

                    if (lastModel) {
                        e.sender.span[0].textContent = lastModel;
                        if ($scope.eventOnChange)
                            $scope.eventOnChange();
                    }

                    // this.$timeout(() => {
                    //     $(focused).focus();
                    // });

                },
                height: $scope.apxPlaceholder?calculatedHeight+27:calculatedHeight,
                dataBound: (e: kendo.ui.DropDownListEvent) => {
                    e.sender.list.mouseenter(() => {
                        $scope.gridMouseEnter = true;
                    }).mouseleave(() => {
                        rowSelected = false;
                        $scope.gridMouseEnter = false;
                        
                    });
                },
                template: '<apx-grid options="gridOptions"></apx-grid>',
                open: () => {
                  
                    // popupIsOpened=true;
                    if (typeof $scope.options.openEvent !== "undefined")
                        $scope.options.openEvent();

                    if ($scope.gridOptions.dataSource.data().length == 0)
                        $scope.gridOptions.gridView.showLoading();

                    if ($scope.gridOptions.grid.dataSource.filter() != undefined && $scope.gridOptions.grid.dataSource.filter().filters.length > 0) {
                        $scope.gridOptions.grid.dataSource.filter([]);
                    }


                    this.$timeout(() => {
                        $scope.gridMouseEnter = true;
                        var grid: any = $scope.gridOptions.grid;

                        if (grid && grid.element != null) {
                            var searchInputs = grid.element.find('.k-filter-row').find('input');

                            _.each(searchInputs, (element: any) => {
                                $(element).unbind('click')
                                    .attr('onclick', '')
                                    .each(function () {
                                        this.onclick = null;
                                    }).on('click', () => {
                                        $(element).focus();
                                        $scope.gridMouseEnter = true;
                                    });
                            });                           
                        }
                        if (grid.virtualScrollable != null)
                            grid.virtualScrollable.refresh();

                        grid.refresh();

                        if (!!$scope.ngModel) {
                            $("[data-uid='" + $scope.ngModel.uid + "']").addClass('k-state-selected');
                        }

                        setTimeout(() => {
                            // focused = document.activeElement;
                            if ($scope.gridOptions.grid.table == null)
                                return;
                            $scope.tableFocuseEvent=true;
                            $scope.gridOptions.grid.table.focus();
                            setTimeout(()=>{
                                $($scope.gridOptions.grid.element.find(".k-filter-row th input")[0]).focus();
                                $scope.gridMouseEnter = true;
                            },500);
                            
                            $scope.gridOptions.grid.table.bind("keydown", function (e) {
                                var lastKeyCode = e.which;
                                switch (lastKeyCode) {
                                    case 27:
                                        $scope.element.find("select").click();
                                        break;
                                }
                            });
                        }, 100);
                    });
                }
            };

            if ($scope.apxPlaceholder && !$scope.apxRequired) {
                $scope.lookupOptions.optionLabel = "{{apxPlaceholder | translate}}";
            }
        }

        setPopupAutoWith = (val) => {
            this.$scope.lookupObject.list.width(val);
        }

    }
}
