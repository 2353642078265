/// <reference path='./../_reference.ts' />
module Apex.Components.Directives {
    "use strict";

    export class ApxSearchDropdown implements angular.IDirective {
        restrict = 'EA';
        transclude = true;
        templateUrl = 'apx.search.dropdown.html';
        replace = true;
        scope = {
            apxOnClick: '&',
            apxArray: '=',
            apxValueId: '@',
            apxValueName: '@',
            buttonDisabled: '@',
            buttonType: '@',
            buttonIcon: '@',
            buttonColor: '@',
            apxGetData: '&',
            apxShowValue: '='
        }
        link = ($scope, $elem, $attr) => {
            $scope.apxIsDropDownLoaded = false;
            $scope.apxIsDropDownLoading = false;
            $(".apx-search-ul").css("max-height", $("#pageBody").height() - 50 + "px");
            var sourceArray;
            $scope.$watch('apxArray', () => {
                if (typeof $scope.apxArray !== "undefined") {
                    _.each($scope.apxArray, (item: any) => {
                        if ($scope.apxShowValue === true) {
                            item.templateRow = item[$scope.apxValueId] + ' - ' + item[$scope.apxValueName];
                        } else {
                            item.templateRow = /*item[$scope.apxValueId] + ' - ' +*/ item[$scope.apxValueName];
                        }
                        
                    });
                    if ($scope.apxArray[0]) {
                        $scope.apxArray[0].isSelected = true;
                    }
                    sourceArray = $scope.apxArray;
                    $scope.searchDropdown.dataSource = _.filter(sourceArray, (item: any) => { return item.templateRow.indexOf($scope.searchDropdown.search) !== -1 });
                }
            });


            $scope.searchDropdown = {
                search: '',
                dataSource: []
            }

            $scope.$watch('searchDropdown.search', (value) => {
                _.forEach(sourceArray, (item: any) => {
                    item.isSelected = false;
                });

                $scope.searchDropdown.dataSource = _.filter(sourceArray, (item: any) => { return item.templateRow.indexOf(value) !== -1 });

                if (typeof $scope.searchDropdown.dataSource[0] !== "undefined") {
                    $scope.searchDropdown.dataSource[0].isSelected = true;
                }
            });

            $scope.ariaExpanded = false;

            var srollToElement = (element: JQuery) => {
                var parentUl = $($elem.find("ul")[1]);
                var firstChild = parentUl.find("li:first-child");
                var toScroll = (element.offset().top - firstChild.offset().top) + 50 - parentUl.innerHeight();
                parentUl.animate({
                    scrollTop: toScroll
                }, 100);
            }
            $scope.$watch('ariaExpanded', (val) => {

                if (val) {
                    $($elem).find(".add-book-overflow").css("display", "block");
                    $(".apx-search-list").on("keydown", (e) => {
                        var selected = _.find($scope.searchDropdown.dataSource, (item: any) => {
                            return item.isSelected === true;
                        });
                        var index = $scope.searchDropdown.dataSource.indexOf(selected);
                        switch (e.which) {
                            case 9:
                            case 40:
                                e.preventDefault();
                                if (index + 1 < $scope.searchDropdown.dataSource.length) {
                                    Core.Utils.Helper.safeApply($scope, () => {
                                        delete $scope.searchDropdown.dataSource[index].isSelected;
                                        $scope.searchDropdown.dataSource[index + 1].isSelected = true;
                                        srollToElement($("#searchLookupItem-" + $scope.searchDropdown.dataSource[index + 1][$scope.apxValueId]));
                                    });
                                }
                                break;
                            case 38:
                                if (index > 0) {
                                    Core.Utils.Helper.safeApply($scope, () => {
                                        delete $scope.searchDropdown.dataSource[index].isSelected;
                                        $scope.searchDropdown.dataSource[index - 1].isSelected = true;
                                        srollToElement($("#searchLookupItem-" + $scope.searchDropdown.dataSource[index - 1][$scope.apxValueId]));

                                    });
                                }
                                break;
                            case 13:
                                Core.Utils.Helper.safeApply($scope, () => {
                                    $scope.pushThisItem(selected);
                                });
                                break;
                        }
                    });
                } else {
                    $($elem).find(".add-book-overflow").css("display", "none");
                    $(".apx-search-list").off("keydown");
                    $scope.searchDropdown.search = '';
                    var parentUl = $($elem.find("ul")[1]);
                    parentUl.animate({
                        scrollTop: 0
                    }, 10);
                }
            });

            $scope.focusInput = () => {

                if ($scope.ariaExpanded) {

                    var inputID = "#seachDropdown-" + $scope.$id;
                    setTimeout(() => {
                        $(inputID).val('');
                        $(inputID).focus();
                    }, 100);
                    if ($scope.apxGetData 
                        && !$scope.apxIsDropDownLoaded
                        && !$scope.apxIsDropDownLoading) {
                        $scope.apxIsDropDownLoading = true;
                        try {
                            $($elem).find("div ul li").removeClass("k-state-selected");
                            var data = $scope.apxGetData();
                            if (data != null) {
                            data.then((response) => {
                                    $scope.apxArray = response.Result; //_.filter(response.Result, (x: any) => (typeof x.WebFormName !== "undefined"));
                                    console.log(response.Result);
                                    $scope.apxIsDropDownLoading = false;
                                    $scope.apxIsDropDownLoaded = true;
                                });
                            }
                        } catch (ex) {
                            console.log(ex);
                            $scope.apxIsDropDownLoading = false;
                        }
                    }

                }
            };

            $scope.pushThisItem = (itm) => {
                $scope.apxOnClick({ val: itm });
                $scope.ariaExpanded = false;
                $scope.search = '';
            }
            $(window).resize(() => {
                $(".apx-search-ul").css("max-height", $("#pageBody").height() - 50 + "px");
            });
        };

        public static factory() {
            var directive = () => {
                return new ApxSearchDropdown();
            };
            return directive;
        }
    }
} 