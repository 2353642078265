/// <reference path='./../_reference.ts' />
module Apex.Components.Directives {
    "use strict";
    export interface IApxNymberScope extends angular.IScope {
        options: Components.IApxNumber;
    } 
    export class ApxNumber implements angular.IDirective {

        restrict = 'E';
        replace = true;
        template = '<div><input type="text" id="apxnumber-{{$id}}" class="k-textbox apx-number" ng-disabled="ngDisabled" ng-model="ngModel" maxlength="20"></div>';
        constructor(private $compile) { }
        scope = {
            options: '=?',
            ngModel: "=?",
            ngDisabled: "=?"
        }

        compile = () => {
            var compile = this.$compile;
            return {
                pre(scope: any, element) {

                    Core.Utils.Helper.directivePreCompile(scope, element, compile, 'input');
                },
                post(scope: IApxNymberScope, element) {
                    var apxNum = element.find("input");
                    var maskOptions = {
                        groupSeparator: ",",
                        placeholder: "0",
                        autoGroup: true,
                        allowMinus: false,
                        digits: 2,
                        digitsOptional: false,
                        clearMaskOnLostFocus: false,
                        autoUnmask: true,
                        allowPlus: false
                    };

                    ApxNumber.init(apxNum, maskOptions, scope.options);

                    scope.$watch('options', (options: Components.IApxNumber) => {
                        if (options != undefined) {
                            var maskOptions;
                            if (options.numberType != undefined) {
                                maskOptions = {
                                    groupSeparator: ",",
                                    placeholder: "0",
                                    autoGroup: true,
                                    allowMinus: options.allowMinus,
                                    digits: options.decimals,
                                    digitsOptional: false,
                                    clearMaskOnLostFocus: false,
                                    autoUnmask: true
                                }
                                ApxNumber.init(apxNum, maskOptions, options);
                            }
                        }
                    });

                    $(element).find("input").focus(function () {
                        $(this).select();
                    });

                    setTimeout(function () {
                        if (!$(element).find("input").val())
                            $(element).find("input").val(0);
                    }, 50);
                }
            }
        }
        // https://github.com/RobinHerbots/jquery.inputmask Plugin repositor

        public static init = (apxNum: any, maskOptions: any, options: Components.IApxNumber) => {

            if (typeof options !== "undefined") {
                maskOptions.digits = options.decimals;
                maskOptions.allowMinus = options.allowMinus;
                if (typeof options.min !== "undefined")
                    maskOptions['min'] = options.min;

                if (typeof options.max !== "undefined")
                    maskOptions['max'] = options.max;
            }

            if (options != undefined && options.numberType != undefined && options.numberType === NumberType.Integer) {
                apxNum.inputmask('integer', maskOptions);
            } else {
                apxNum.inputmask('decimal', maskOptions);
            }

            apxNum.keydown(() => {
                if (apxNum.val() === '')
                    apxNum.val(0).change();
            });
        }

        public static factory() {
            var directive = ($compile) => {
                return new ApxNumber($compile);
            };

            directive['$inject'] = ['$compile'];

            return directive;
        }
    }
} 