/// <reference path='./../_reference.ts' />
module Apex.Components.Directives {
    "use strict";

    export class ApxLoading implements angular.IDirective {
        restrict = "E";
        replace = true;
        scope = {
            visibled: "="
        }
        templateUrl = 'apx.loading.html';
        link(scope, element, attrs, controller) {
            $(element).hide();
       
            scope.$watch("visibled", (value, oldval) => {
                if (value !== undefined) {
                    if (value) {
                        $(element).show();
                    } else {
                        setTimeout(() => {
                            $(element).slideUp("slow", () => {
                                $(element).hide();
                            });
                        }, 250);
                    }
                }
            });
        }
        public static factory() {
            var directive = () => {
                var apxBlock = new ApxLoading();
                return apxBlock;
            };
            return directive;
        }
    }
}