/// <reference path='./../_reference.ts' />
module Apex.Components.Directives {
    "use strict";
    export class ApxFilterGroup implements angular.IDirective {
        restrict = "AE";
        templateUrl = 'apx.filter.group.html';
        scope = {
            /** initialized object with default values*/
            apxFilterGroupOptions: '=',
            ngModel: '='
        };
        controller = Apex.Components.Controllers.ApxFilterGroupController.getName();

        constructor(private $compile) { }
        compile = () => {
            var compile = this.$compile;
            return {
                pre(scope, element, iAttrs) {
                },
                post(scope, element, iAttrs, controller) {
                    if (!scope.apxFilterGroupOptions.GetFilterCriteria) {
                        scope.apxFilterGroupOptions.GetFilterCriteria = (): string => {
                            scope.apxFilterGroupOptions.stringCriteria = controller.$scope.events.getFilter();
                            return scope.apxFilterGroupOptions.stringCriteria;
                        }
                    }
                }
            }
        }
        public static factory() {
            var directive = ($compile) => {
                return new ApxFilterGroup($compile);
            };
            directive['$inject'] = ['$compile'];
            return directive;
        }
    }

    export class ApxFilterCondition implements angular.IDirective {
        restrict = "AE";
        templateUrl = 'apx.filter.condition.html';
        scope = {
            apxFilterGroupOptions: '=',
            operand: '=',
            ngModel: '='
        };
        controller = Apex.Components.Controllers.ApxFilterConditionController.getName();

        constructor(private $compile) { }
        compile = () => {
            var compile = this.$compile;
            return {
                pre(scope, element, iAttrs) {
                },
                post(scope, element, iAttrs) {
                }
            }
        }
        public static factory() {
            var directive = ($compile) => {
                return new ApxFilterCondition($compile);
            };
            directive['$inject'] = ['$compile'];
            return directive;
        }
    }
}




module Apex.Components.Controllers {

    export interface ApxFilterGroupScope extends angular.IScope {
        compiler: any;
        filterGroup: {
            selected?: String,
            DataSource: any[]
        }
        htmlTemplate: {
            url?: string;
        },
        expresion: {
            showLastValue?: boolean
        }
        events: {
            addFilterCondition: Function;
            addFilterGroup: Function;
            clearAll: Function;
            getFilter: Function;
            changeChildElements: Function;
        }
    }

    export class ApxFilterGroupController extends Core.Controller {
        public static $inject = [Globals.scope, "$timeout", "$element", "$translate"];
        constructor(private $scope: ApxFilterGroupScope, public $timeout, private $element, private $translate) {
            super(arguments, ApxFilterGroupController.$inject);
            this.initialization($scope);
            $scope.compiler = Apex.Core.Utils.Helper.getServiceFromJs("$compile");

            $scope.$watch("filterGroup.selected", (val, old) => {
                if (val && val == "ჯგუფის დამატება") {
                    $scope.events.addFilterGroup();

                } else
                    if (val && val == "გასუფთავება")
                        $scope.events.clearAll();

                //not selected values
                if (val == "ჯგუფის დამატება" || val == "გასუფთავება" || val == "Get Filter") {
                    if (!old)
                        $scope.filterGroup.selected = "Add";
                    else
                        $scope.filterGroup.selected = String(old);
                }

            });

        }
        initialization(scope: ApxFilterGroupScope) {
            scope.expresion = {};
            scope.filterGroup = {
                DataSource: [
                    { Name: 'And' },
                    { Name: "Or" },
                    { Name: "Equeal" },
                    { Name: "ჯგუფის დამატება" },
                    { Name: "გასუფთავება" }
                ]
            };
            scope.htmlTemplate = {};
            scope.events = {
                addFilterCondition: this.addFilterCondition,
                addFilterGroup: this.addFilterGroup,
                clearAll: this.clearAll,
                getFilter: this.getFilter,
                changeChildElements: this.changeChildElements
            };
        }

        getFilter = (): String => {

            var _filterCriteria: String = "";
            var filterConditions = $("#filter-condition-" + this.$scope.$id + " > #inner-body").children("apx-filter-condition").find("#filter-condition-content");
            var filterGroups = $("#filter-condition-" + this.$scope.$id + " > #inner-body").children("apx-filter-group").children("#parent-content");

            for (var index = 0; index < filterConditions.length; index++) {
                var sc = <ApxFilterConditionScope>angular.element(filterConditions[index]).scope();
                // if(!sc.conditionForm.$valid)
                //     break;
                if (index > 0)
                    _filterCriteria += " " + this.$scope.filterGroup.selected + " ";
                _filterCriteria += sc.methods.getStringCriteria();
            }

            for (var index1 = 0; index1 < filterGroups.length; index1++) {
                var fg_sc = <ApxFilterGroupScope>angular.element(filterGroups[index1]).scope();
                var stringCriteria=fg_sc.events.getFilter();
                if(stringCriteria)
                    _filterCriteria += " " + this.$scope.filterGroup.selected + " " + " ( " + stringCriteria + " )";
            }
            return _filterCriteria;
        }


        addFilterCondition = () => {
            var el = this.$scope.compiler($('<apx-filter-condition apx-col-span="12" apx-filter-group-options="apxFilterGroupOptions"></apx-filter-condition>'))(this.$scope);
            $(this.$element).find("#inner-body").first().append(el);
            this.$timeout(() => {
                this.changeChildElements(1);
            });
        }
        addFilterGroup = () => {
            var el = this.$scope.compiler($('<apx-filter-group apx-col-span="12" apx-filter-group-options="apxFilterGroupOptions"></apx-filter-group>'))(this.$scope);
            $(this.$element).find("#inner-body").first().append(el);
            this.changeChildElements(1);
        }
        clearAll = () => {
            $(this.$element).find("#inner-body #parent-content").remove();
            $(this.$element).find("#inner-body #filter-condition-content").remove();
            this.changeChildElements(0);
        }
/** 1 - add; 0 - remobve */
        changeChildElements = (val:number) => {
            this.addOrRemoveLastValue(val);
        }

        addOrRemoveLastValue = (val:number) => {
            var lastValue = $("#last-value");
            if (lastValue.length == 0 && val) {
                var el = this.$scope.compiler(`
                <div id="last-value" apx-col-span="1" style="width:130px">
                    <apx-textbox id="last-value-input" ng-model="apxFilterGroupOptions.value"  required maxlength=50></apx-textbox>
                </div>
                `)(this.$scope);

                $("#parent-content").append(el);
                this.$timeout(() => {
                    var height = $("#parent-content").height();
                    lastValue.height(height);
                    var lastValueInput = $(el).find("#last-value-input");
                    lastValueInput.css("margin-top", (height / 2) + "px");
                });

            }
            else {
                var height = $("#inner-body").height();

                if (height && height > 5) {
                    lastValue.height(height);
                    var lastValueInput = lastValue.find("#last-value-input");
                    lastValueInput.css("margin-top", (height / 2) + (lastValueInput.height() / 2) + "px");
                } else {
                    lastValue.remove();
                }
            }
        }

    }


    export interface ApxFilterConditionScope extends angular.IScope {
        filterCondition: {
            operandTypes: any[],
            model?: any,
            value?: string,
            lastValue?: string
        },
        fieldsGroup: {
            DataSource: any[],
            model?: any,
            type?: string
        }
        events: {
            removeSelf: Function
        },
        methods: {
            getStringCriteria: Function
        },
        conditionForm:any,
        additionalVariable:{
            showValu?:boolean
        }
    }

    export class ApxFilterConditionController extends Core.Controller {
        public static $inject = [Globals.scope, "$timeout", "$element", "$translate"];
        constructor(private $scope: ApxFilterConditionScope, public $timeout, private $element, private $translate) {
            super(arguments, ApxFilterConditionController.$inject);
            this.initialization($scope);

            $scope.$watch('apxFilterGroupOptions', (val: any) => {
                if (val) {
                    for (var propertyName in val.fields) {
                        $scope.fieldsGroup.DataSource.push({ Name: propertyName, TypeOf: typeof val.fields[propertyName] });
                    }
                }
            });
            $scope.$watch('filterCondition.model',(val)=>{
                if(val){
                    if(val=="==null" || val=="!=null")
                        this.$scope.additionalVariable.showValu=false;
                        else
                        this.$scope.additionalVariable.showValu=true;
                }
                
            });
        }

        initialization(scope: ApxFilterConditionScope) {
            scope.additionalVariable={
                showValu:true
            }
            
            scope.filterCondition = {
                operandTypes: [
                    { Operand: "==", Name:this.$translate.instant('Equals')  },
                    { Operand: "!=", Name: this.$translate.instant("DoesNotEqual")},
                    { Operand: ">", Name:this.$translate.instant("IsGreaterThen") },
                    { Operand: ">=", Name: this.$translate.instant("IsGreaterThanOrEqualTo")},
                    { Operand: "<", Name:this.$translate.instant("IsLessThan")},
                    { Operand: "<=", Name:this.$translate.instant("IsLessThanOrEqualTo")},
                    { Operand: "like", Name: this.$translate.instant("Like") },
                    { Operand: "==null", Name:this.$translate.instant("IsNull")},
                    { Operand: "!=null", Name: this.$translate.instant("IsNotNull") }
                ]
            };
            scope.events = {
                removeSelf: this.removeSelf
            };
            scope.methods = {
                getStringCriteria: this.getStringCriteria
            }
            scope.fieldsGroup = {
                DataSource: []
            };
        }
        removeSelf = () => {
            var parentScope = <ApxFilterGroupScope>angular.element($(this.$element).parent().parent()).scope();
            $(this.$element).remove();
            parentScope.events.changeChildElements(0);


        }

        getStringCriteria = (): string => {
            if(this.$scope.filterCondition.model=="==null" || this.$scope.filterCondition.model=="!=null")
                return this.$scope.fieldsGroup.model + " " + this.$scope.filterCondition.model;

            return this.$scope.fieldsGroup.model + " " + this.$scope.filterCondition.model + " '" + this.$scope.filterCondition.value + "'";
        }
        getValueByType = () => {
            // this.$scope.   
        }

    }
}