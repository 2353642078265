/// <reference path='./../_reference.ts' />
module Apex.Components.Directives {
    "use strict";
    export class ApxCheckbox implements angular.IDirective {
        restrict = "AE";
        replace = true;
        require = 'ngModel';
        scope = {
            ngModel: '=',
            apxName: '@',
            ngDisabled: '=ngDisabled'
        };
        templateUrl = 'apx.checkbox.html';
        link(scope, element, attrs, controller) {
            element.removeAttr('ng-disabled').removeAttr('disabled').removeAttr('ng-model').removeAttr('apx-name').removeAttr('ngDisabled');
            var span = element.find("span");
            if (attrs.hasOwnProperty('withoutCaption')) {
                if (span.length > 0) {
                    span.replaceWith("<span></span>");
                }
            }
            if(attrs.hasOwnProperty('withoutTranslate')){
                if (span.length > 0) {
                    span.replaceWith("<span>"+String(scope.apxName)+"</span>");
                }
            }
            scope.scopeId = scope.$id;
        }

        public static factory() {
            var directive = () => {
                return new ApxCheckbox();
            };
            return directive;
        }
    }
} 